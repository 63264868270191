import React, { useState, useEffect } from "react";
import axios from "axios";
import { useOktaAuth } from "@okta/okta-react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Avatar,
} from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import {
  Info as InfoIcon,
  Close as CloseIcon,
  More,
} from "@mui/icons-material";
import { randomId } from "@mui/x-data-grid-generator";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("en");

// Set default timezone to CST
dayjs.tz.setDefault("America/Chicago");

const BedsDetailedActivityButton = ({ selectedFacilityCode }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const accessTokenResponse = authState.accessToken;
  const bearerToken = accessTokenResponse?.accessToken;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      const fetchData = async () => {
        const url =
          process.env.REACT_APP_BASE_API +
          `/bed-roster-activity/${selectedFacilityCode}`;
        try {
          const response = await axios.get(url, {
            headers: {
              Authorization: bearerToken,
              "Content-Type": "application/json",
            },
          });
          const id = randomId();
          const updatedData = response.data.map((obj) => {
            if (obj) {
              return {
                ...obj,
                id: randomId(),
                lastAdmitDate: dayjs(obj.lastAdmitDate)
                  .locale("en")
                  .tz("America/Chicago")
                  .format("ddd, DD MMM YYYY HH:mm:ss z"),
                lastDischargeDate:
                  dayjs(obj.lastDischargeDate)
                    .locale("en")
                    .tz("America/Chicago")
                    .format("ddd, DD MMM YYYY HH:mm:ss z") === "Invalid Date"
                    ? "Not Available"
                    : dayjs(obj.lastDischargeDate)
                        .locale("en")
                        .tz("America/Chicago")
                        .format("ddd, DD MMM YYYY HH:mm:ss z"),
              };
            }
          });
          setData(updatedData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [open]);

  const columns = [
    { field: "facilityName", headerName: "Facility Name", width: 250 },
    { field: "roomNumber", headerName: "Room Number", width: 120 },

    { field: "bedNumber", headerName: "Bed Number", width: 100 },
    { field: "bedType", headerName: "Bed Type", width: 180 },

    { field: "bedStatus", headerName: "Bed Status", width: 150 },
    { field: "staffed", headerName: "Staffed", width: 120 },

    // { field: "facilityCode", headerName: "Facility Code", width: 200 },
    {
      field: "lastAdmitDate",
      headerName: "Last Admission/Start of Use of that Room Date",
      width: 200,
    },
    {
      field: "lastDischargeDate",
      headerName: "Last Discharge Date/End of Use of that Room Date",
      width: 200,
    },
    // {
    //   field: "lastMessageReceivedDate",
    //   headerName: "Last Message Received Date",
    //   width: 200,
    // },
    // { field: "lastUpdate", headerName: "Last Update", width: 200 },
  ];

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        sx={{ marginRight: "5px" }}
        onClick={handleClickOpen}
        startIcon={<More />}
      >
        Beds Detailed Activity
      </Button>
      <Dialog maxWidth="xl" open={open} onClose={handleClose}>
        <DialogTitle>
          <ListItem>
            <ListItemIcon>
              <Avatar variant="rounded" sx={{ bgcolor: "#2F5E8B" }}>
                <More fontSize="small" />
              </Avatar>
            </ListItemIcon>
            <ListItemText disableTypography>
              {" "}
              <Typography variant="h6">Beds Detailed Activity</Typography>
            </ListItemText>
          </ListItem>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            style={{ position: "absolute", right: 16, top: 16 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div style={{ height: "80vh", width: "100%" }}>
            <DataGridPro
              slots={{
                toolbar: GridToolbar,
              }}
              slotProps={{
                toolbar: {
                  csvOptions: { allColumns: true },
                  printOptions: { disableToolbarButton: true },
                },
              }}
              rows={data}
              columns={columns}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BedsDetailedActivityButton;
