import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@mui/styles";
import "./LiveBedCapacity.css";
import PsychiatricBedsGroup from "./PsychiatricBedsGroup";
import { useMediaQuery } from "react-responsive";
import {
  hccOptions,
  iowaCounties,
  rmccOptions,
} from "../../services/Constants";
import {
  FormControl,
  InputLabel,
  TextField,
  Box,
  IconButton,
  Chip,
  Badge,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Select, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FilterList } from "@mui/icons-material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import OverviewGroup from "./OverviewGroup";
import AdultBedsGroup from "./AdultBedsGroup";
import PediatricBedsGroup from "./PediatricBedsGroup";
import MomAndBabyGroup from "./MomAndBabyGroup";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "#2F5E8C",
  },
  text: {
    marginLeft: "-20px",
    fontStyle: "bold",
  },
  icon: {
    color: "#2F5E8C",
    marginLeft: "-10px",
  },
  tabs: {
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .Mui-selected": {
      backgroundColor: "#2F5E8C",

      color: "white",
      boxShadow: "4px 4px 20px 0px black",
    },
  },
});

function a11yProps(index) {
  return {
    id: `full-width-tabpanel-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  sx: {
    "&& .Mui-selected": {
      backgroundColor: "pink",
    },
  },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "auto",
    },
  },
};

export default function BedCapacityGroup({ beds, days }) {
  const [value, setValue] = React.useState(
    parseInt(sessionStorage.getItem("tabValue")) || 0
  );
  const [showButton, setShowButton] = useState(false);
  const [filterBedsData, setFilterBedsData] = React.useState(beds);
  const [data, setData] = React.useState(beds);
  const [openExportPopup, setOpenExportPopup] = useState(false);
  const [openFilterPopup, setOpenFilterPopup] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [filterInput, setFilterInput] = useState("");
  const [typeFilterInput, setTypeFilterInput] = useState("");
  const [cityFilterInput, setCityFilterInput] = useState("");
  const [countyFilterInput, setCountyFilterInput] = useState([]);
  const [zipFilterInput, setZipFilterInput] = useState("");
  const [ahaFilterInput, setAhaFilterInput] = useState("");
  const [nmdsFilterInput, setNmdsFilterInput] = useState("");
  const [rmccFilterInput, setRmccFilterInput] = useState([]);
  const [hccFilterInput, setHccFilterInput] = useState([]);
  const [filtersActive, setFiltersActive] = useState(false); // State to track if any filters are active
  const [activeFiltersCount, setActiveFiltersCount] = useState(0); // Count of active filters

  const classes = useStyles();

  const isAllCountiesSelected =
    iowaCounties.length > 0 &&
    countyFilterInput?.length === iowaCounties.length;

  const isAllRmccSelected =
    rmccOptions.length > 0 && rmccFilterInput?.length === rmccOptions.length;

  const isAllHccSelected =
    hccOptions.length > 0 && hccFilterInput?.length === hccOptions.length;

  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 480px)",
  });

  const isTabDevice = useMediaQuery({
    query: "(max-device-width: 1150px) ",
  });

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        // replace 200 with the scroll position you want
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const handleExportPopupClose = () => {
    setOpenExportPopup(false);
  };
  const handleFiltersPopupClose = () => {
    setOpenFilterPopup(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    sessionStorage.setItem("tabValue", newValue);
  };

  const resetAllFilters = () => {
    setFilterInput("");
    setTypeFilterInput("");
    setCityFilterInput("");
    setCountyFilterInput([]);
    setZipFilterInput("");
    setAhaFilterInput("");
    setNmdsFilterInput("");
    setRmccFilterInput([]);
    setHccFilterInput([]);
    setFiltersActive(false); // Reset the filter activity flag
    setActiveFiltersCount(0); // Reset the active filters count
  };

  function replaceNullValues(data) {
    // Loop through each object in the array
    for (let i = 0; i < data.length; i++) {
      // Loop through each property in the object
      for (const prop in data[i]) {
        // Check if the property value is null, "NA", or "NAN"
        if (data[i][prop] === null) {
          // Replace the value with "-"
          data[i][prop] = "-";
        }
      }
    }
    // Return the modified array of objects
    return data;
  }

  const filteredData = replaceNullValues(filterBedsData).filter((hospital) => {
    // Filter based on hospital name
    if (
      filterInput &&
      !hospital.HOSPITAL_NAME.toLowerCase().includes(filterInput.toLowerCase())
    ) {
      return false;
    }

    if (
      typeFilterInput &&
      !hospital.HOSPITAL_TYPE.toLowerCase().includes(
        typeFilterInput.toLowerCase()
      )
    ) {
      return false;
    }

    if (
      cityFilterInput &&
      !hospital.HQ_CITY.toLowerCase().includes(cityFilterInput.toLowerCase())
    ) {
      return false;
    }

    if (
      zipFilterInput &&
      !hospital.HQ_ZIP_CODE.toLowerCase().includes(zipFilterInput.toLowerCase())
    ) {
      return false;
    }

    if (
      ahaFilterInput &&
      !hospital.Facility_AHA_ID.toString()
        .toLowerCase()
        .includes(ahaFilterInput.toLowerCase())
    ) {
      return false;
    }
    if (hospital && hospital.NDMS) {
      const nmdsFilterInputString = nmdsFilterInput
        ? nmdsFilterInput.toString().toLowerCase()
        : "";
      const hospitalNDMString = hospital.NDMS.toString().toLowerCase();

      if (!hospitalNDMString.includes(nmdsFilterInputString)) {
        return false;
      }
    }

    if (
      countyFilterInput.length &&
      !countyFilterInput.includes(hospital.COUNTY_NAME)
    ) {
      return false;
    }

    if (hccFilterInput.length && !hccFilterInput.includes(hospital.HCC)) {
      return false;
    }

    if (rmccFilterInput.length && !rmccFilterInput.includes(hospital.RMCC)) {
      return false;
    }

    return true;
  });

  const checkFiltersActive = () => {
    let count = 0;
    if (filterInput) count++;
    if (typeFilterInput) count++;
    if (cityFilterInput) count++;
    if (countyFilterInput.length > 0) count++;
    if (zipFilterInput) count++;
    if (ahaFilterInput) count++;
    if (nmdsFilterInput) count++;
    if (rmccFilterInput.length > 0) count++;
    if (hccFilterInput.length > 0) count++;

    setActiveFiltersCount(count);

    if (count > 0) {
      setFiltersActive(true);
    } else {
      setFiltersActive(false);
    }
  };

  useEffect(() => {
    setData(filteredData);
    checkFiltersActive();
  }, [
    filterInput,
    typeFilterInput,
    cityFilterInput,
    zipFilterInput,
    ahaFilterInput,
    nmdsFilterInput,
    countyFilterInput,
    hccFilterInput,
    rmccFilterInput,
  ]);

  const TABS = [
    { label: "Overview", a11yProps: { index: 0 } },
    { label: "Adult", a11yProps: { index: 1 } },
    { label: "Pediatric", a11yProps: { index: 2 } },

    { label: "Psychiatric", a11yProps: { index: 3 } },
    { label: "Mom and Baby", a11yProps: { index: 4 } },
    { label: "Substance Abuse", a11yProps: { index: 5 } },
  ];

  const refreshData = (e) => {
    setButtonLoading(true);
    setData(beds);
    setButtonLoading(false);
  };
  return (
    <div>
      <Box sx={{ margin: "-7px", borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="inherit"
          variant="scrollable"
        >
          {TABS.map((tab, index) => (
            <Tab
              key={`basic-tab-${index}`}
              aria-label={`basic tab ${index + 1}`}
              id={`basic-tab-${index}`}
              sx={{
                borderRight: (theme) => `1px solid ${theme.palette.divider}`,
              }}
              label={tab.label}
              {...tab.a11yProps}
              disabled={index >= 5}
            />
          ))}
        </Tabs>
      </Box>

      <div
        className="occupancyBox1"
        style={{
          display: "flex",
          flexDirection: isMobileDevice ? "column" : "row",
          marginTop: "6px",
          paddingLeft: "30px",
        }}
      >
        <span
          style={{
            fontSize: "14px",
            fontWeight: "bolder",
            marginRight: "10px",
            marginTop: "15px",
          }}
        >
          Filters :{" "}
        </span>

        <Box>
          <TextField
            style={{ width: 160, margin: 2.5, color: "black" }}
            size="small"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            id="filled-search-1"
            type="search"
            label="Hospital name"
            variant="filled"
            value={filterInput}
            onChange={(e) => setFilterInput(e.target.value)}
          />

          <FormControl
            className="filter-group"
            size="small"
            style={{ width: 160, margin: 2.5 }}
          >
            <TextField
              variant="filled"
              size="small"
              select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name-hospital-type"
              value={typeFilterInput}
              onChange={(e) => setTypeFilterInput(e.target.value)}
              label="Hospital Type"
            >
              <MenuItem value="">Hospital Type (All types)</MenuItem>
              <MenuItem value="Critical Access Hospital">
                Critical Access Hospital
              </MenuItem>
              <MenuItem value="Short Term Acute Care Hospital">
                Short Term Acute Care Hospital
              </MenuItem>
            </TextField>
          </FormControl>
          <FormControl
            className="filter-group"
            size="small"
            variant="filled"
            style={{ width: 150, margin: 2.5 }}
          >
            <InputLabel id="demo-multiple-chip-label">County</InputLabel>

            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-checkbox-county"
              displayEmpty
              variant="filled"
              multiple={true}
              value={countyFilterInput || []}
              MenuProps={MenuProps}
              onChange={(event) =>
                event.target.value.includes("all")
                  ? setCountyFilterInput(
                      event.target.value.length === iowaCounties.length + 1
                        ? []
                        : iowaCounties
                    )
                  : setCountyFilterInput(event.target.value)
              }
              label="County"
              renderValue={(selected) => selected.join(", ")}
            >
              <MenuItem value="all">
                <Checkbox
                  checked={isAllCountiesSelected}
                  indeterminate={isAllCountiesSelected}
                />
                <ListItemText
                  primary={
                    isAllCountiesSelected ? "Unselect All" : "Select All"
                  }
                />
              </MenuItem>
              {iowaCounties.map((c) => {
                return (
                  <MenuItem key={c} value={c}>
                    {" "}
                    <Checkbox checked={countyFilterInput.indexOf(c) > -1} />
                    <ListItemText primary={c} />{" "}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Box display="inline">
            {isMobileDevice ? (
              <IconButton
                style={{ color: "#2F5E8C" }}
                id="demo-positioned-button-filters"
                size="small"
                tooltip="More FIlters"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={setOpenFilterPopup}
              >
                <FilterList />
              </IconButton>
            ) : (
              <Button
                style={{ margin: "7px" }}
                variant="outlined"
                size="small"
                endIcon={<FilterList />}
                id="demo-positioned-button-filters"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={setOpenFilterPopup}
              >
                More Filters
              </Button>
            )}

            {filtersActive ? (
              <Badge badgeContent={activeFiltersCount} color="error">
                <Button
                  variant="contained"
                  size="small"
                  endIcon={<HighlightOffIcon />}
                  id="demo-positioned-button-filters"
                  aria-controls={open ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={resetAllFilters}
                >
                  Clear Filters
                </Button>
              </Badge>
            ) : (
              <Button
                disabled
                variant="outlined"
                size="small"
                endIcon={<HighlightOffIcon />}
                id="demo-positioned-button-filters"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={resetAllFilters}
              >
                Clear Filters
              </Button>
            )}
            {/* {showButton && (
              <LoadingButton
                style={{ marginInline: "7px" }}
                size="small"
                onClick={(e) => refreshData(e)}
                loading={buttonLoading}
                loadingPosition="end"
                variant="outlined"
                endIcon={<RefreshOutlined />}
              >
                {" "}
                Refresh
              </LoadingButton>
            )} */}
            <Chip
              size="small"
              variant="outlined"
              style={{ marginLeft: "10px", color: "#404040" }}
              label={
                <span style={{ fontWeight: "bold" }}>
                  Total Hospitals: {data.length}
                </span>
              }
            >
              {" "}
            </Chip>
          </Box>
          <Dialog open={openFilterPopup} onClose={handleFiltersPopupClose}>
            <DialogTitle>
              {" "}
              <FilterList /> Filters :{" "}
            </DialogTitle>
            <DialogContent>
              <TextField
                style={{ width: 120, margin: 2.5 }}
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                size="small"
                id="filled-search-2"
                type="search"
                label="City"
                variant="filled"
                value={cityFilterInput}
                onChange={(e) => setCityFilterInput(e.target.value)}
              />
              <TextField
                style={{ width: 120, margin: 2.5 }}
                size="small"
                id="filled-search-3"
                type="search"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                label="Zip"
                variant="filled"
                value={zipFilterInput}
                onChange={(e) => setZipFilterInput(e.target.value)}
              />
              <TextField
                style={{ width: 150, margin: 2.5 }}
                size="small"
                id="filled-search-4"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                type="search"
                label="AHA Number"
                variant="filled"
                value={ahaFilterInput}
                onChange={(e) => setAhaFilterInput(e.target.value)}
              />
              <FormControl
                className="filter-group"
                size="small"
                variant="filled"
                style={{ minWidth: 100, width: "auto", margin: 2.5 }}
              >
                <InputLabel id="demo-multiple-name-label-hcc">HCC</InputLabel>

                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip-hcc"
                  displayEmpty
                  variant="filled"
                  multiple={true}
                  value={hccFilterInput || []}
                  MenuProps={MenuProps}
                  onChange={(event) =>
                    event.target.value.includes("all")
                      ? setHccFilterInput(
                          event.target.value.length === hccOptions.length + 1
                            ? []
                            : hccOptions
                        )
                      : setHccFilterInput(event.target.value)
                  }
                  label="HCC"
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  <MenuItem value="all">
                    <Checkbox
                      checked={isAllHccSelected}
                      indeterminate={isAllHccSelected}
                    />
                    <ListItemText
                      primary={isAllHccSelected ? "Unselect All" : "Select All"}
                    />
                  </MenuItem>
                  {hccOptions.map((c) => {
                    return (
                      <MenuItem key={c} value={c}>
                        {" "}
                        <Checkbox checked={hccFilterInput.indexOf(c) > -1} />
                        <ListItemText primary={c} />{" "}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl
                className="filter-group"
                size="small"
                variant="filled"
                style={{ minWidth: 100, width: "auto", margin: 2.5 }}
              >
                <InputLabel id="demo-multiple-name-label-rmcc">RMCC</InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip-rmcc"
                  displayEmpty
                  variant="filled"
                  multiple={true}
                  value={rmccFilterInput || []}
                  MenuProps={MenuProps}
                  onChange={(event) =>
                    event.target.value.includes("all")
                      ? setRmccFilterInput(
                          event.target.value.length === rmccOptions.length + 1
                            ? []
                            : rmccOptions
                        )
                      : setRmccFilterInput(event.target.value)
                  }
                  label="RMCC"
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  <MenuItem value="all">
                    <Checkbox
                      checked={isAllRmccSelected}
                      indeterminate={isAllRmccSelected}
                    />
                    <ListItemText
                      primary={
                        isAllRmccSelected ? "Unselect All" : "Select All"
                      }
                    />
                  </MenuItem>
                  {rmccOptions.map((c) => {
                    return (
                      <MenuItem key={c} value={c}>
                        {" "}
                        <Checkbox checked={rmccFilterInput.indexOf(c) > -1} />
                        <ListItemText primary={c} />{" "}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl
                className="filter-group"
                size="small"
                style={{ width: 160, margin: 2.5 }}
              >
                <TextField
                  size="small"
                  variant="filled"
                  select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name-ndms"
                  value={nmdsFilterInput}
                  onChange={(e) => setNmdsFilterInput(e.target.value)}
                  label="NDMS"
                >
                  <MenuItem value="">All Hospitals</MenuItem>
                  <MenuItem value="1">NDMS Hospitals</MenuItem>
                  <MenuItem value="0">Non-NDMS Hospitals</MenuItem>
                </TextField>
              </FormControl>
            </DialogContent>
            <DialogActions>
              {filtersActive && (
                <Box sx={{ marginRight: "10px" }}>
                  <Badge badgeContent={activeFiltersCount} color="error">
                    <Button
                      variant="contained"
                      size="small"
                      endIcon={<HighlightOffIcon />}
                      id="demo-positioned-button-filters"
                      aria-controls={open ? "demo-positioned-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={resetAllFilters}
                    >
                      Clear Filters
                    </Button>
                  </Badge>
                </Box>
              )}
              <Button
                onClick={() => {
                  setOpenFilterPopup(false);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </div>

      <Box sx={{ width: "100%" }}>
        <TabPanel value={value} index={0} id="basic-tab-1">
          <OverviewGroup data={data} days={days} />
        </TabPanel>
        <TabPanel value={value} index={1} id="basic-tab-2">
          <AdultBedsGroup data={data} days={days} />
        </TabPanel>
        <TabPanel value={value} index={2} id="basic-tab-3">
          <PediatricBedsGroup data={data} days={days} />
        </TabPanel>
        <TabPanel value={value} index={3} id="basic-tab-4">
          <PsychiatricBedsGroup data={data} days={days} />
        </TabPanel>
        <TabPanel value={value} index={4} id="basic-tab-5">
          <MomAndBabyGroup data={data} days={days} />
        </TabPanel>
        <TabPanel value={value} index={5} id="basic-tab-6">
          {/* <TotalAvailableBeds beds={data} type="SUD_" />
          <MentalHealthBedsTable data={data} /> */}
        </TabPanel>
      </Box>
    </div>
  );
}
