import { Square } from '@mui/icons-material';
import React from 'react';

const TrendHeaderText = ({ days }) => {
  return (
    <div className="flex">
      <div className="inline-flex">
        Last {days === 1 || days === null ? 14 : days} Days Trend
      </div>
      <div
        className="inline-flex"
        style={{ fontSize: "11px", fontWeight: "normal" }}
      >
        <Square style={{ color: "#547ea5", fontSize: "10px" }} />{" "}
        Physical Capacity
        <Square
          style={{
            color: "#9ec9d3",
            fontSize: "10px",
            marginLeft: "5px",
          }}
        />{" "}
        Staffed Capacity
      </div>
    </div>
  );
};

export default TrendHeaderText;
