import React from "react";
import { Typography, Stack } from "@mui/material";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";

const PercentageChangeStyles = (value) => {
  if (value == 0) {
    return "No Change";
  }

  if (value === null || value == undefined || isNaN(value)) {
    return "-";
  }
  const isNegative = Math.sign(value);
  const getDirectionAndValue = (value) => {
    if (isNegative === -1) {
      return (
        <Stack style={{ display: "inline-block" }} direction="row">
          <span
            style={{
              color:
                isNegative !== -1
                  ? "red"
                  : isNegative == -1
                  ? "green"
                  : "black",
            }}
          >
            {" "}
            {isNegative !== -1 ? (
              <ArrowUpward style={{ fontSize: "20px" }} />
            ) : isNegative == -1 ? (
              <ArrowDownward style={{ fontSize: "20px" }} />
            ) : (
              ""
            )}{" "}
          </span>
          <Typography
            style={{
              display: "inline",
            }}
          >
            {" "}
            {value == 0 ? "No Change" : Math.abs(value).toFixed(2) + "%"}
          </Typography>
        </Stack>
      );
    } else {
      return (
        <Stack style={{ display: "inline-block" }} direction="row">
          <span
            style={{
              color:
                isNegative !== -1
                  ? "red"
                  : isNegative == -1
                  ? "green"
                  : "black",
            }}
          >
            {" "}
            {isNegative !== -1 ? (
              <ArrowUpward style={{ fontSize: "20px" }} />
            ) : isNegative == -1 ? (
              <ArrowDownward style={{ fontSize: "20px" }} />
            ) : (
              ""
            )}{" "}
          </span>
          <Typography
            style={{
              display: "inline",
            }}
          >
            {" "}
            {value == 0 ? "No Change" : Math.abs(value).toFixed(2) + "%"}
          </Typography>
        </Stack>
      );
    }
  };

  const processedValue = getDirectionAndValue(value);
  return processedValue;
};
export default PercentageChangeStyles;
