import React, { useState, useMemo, useEffect } from "react";
import "./index.css";
import { makeStyles } from "@mui/styles";
import HotelIcon from "@mui/icons-material/Hotel";
import { useOktaAuth } from "@okta/okta-react";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { useMediaQuery } from "react-responsive";
import {
  ForwardToInbox,
  ReceiptLongOutlined,
  SyncAlt,
} from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    padding: theme.spacing(0),
    // necessary for content to be below app bar
  },
  welcomeBlock: {
    flexGrow: 1,
    marginTop: 70,
    padding: theme.spacing(0),
  },
  mobileWelcomeBlock: {
    marginTop: 80,
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  viewStyling: {
    padding: 30,
    fontFamily: "Arial",
    fontSize: 15,
    width: "100%",
  },
}));

const links = [
  {
    condition: (userInfo) =>
      userInfo?.indexOf("IDPH_IA_Dashboard_Admins") !== -1,
    href: "/admin",
    text: "Admin Panel",
    icon: <AdminPanelSettingsIcon fontSize="large" />,
  },
  {
    condition: (userInfo) =>
      userInfo?.indexOf("IDPH_IA_Bed_Occupancy_Users") !== -1,
    href: "/bedcapacity",
    text: "Live Bed Capacity Dashboard",
    icon: <HotelIcon fontSize="large" />,
  },
  {
    condition: (userInfo) =>
      userInfo?.indexOf("IDPH_IA_Bed_Roster_Admins") !== -1,
    href: "/bedsroster",
    text: "Bed Capacity Roster",
    icon: <SyncAlt fontSize="large" />,
  },
  {
    condition: (userInfo) =>
      userInfo?.indexOf("IDPH_IA_SUPER_ADMINS") !== -1,
    href: "/bedsroster-log-book",
    text: "Bed Roster Logs",
    icon: <ReceiptLongOutlined fontSize="large" />,
  },
];

const renderLinks = (userInfo, classes) => {
  return links
    .filter((link) => link.condition(userInfo))
    .map((link, index) => (
      <a href={link.href} key={index}>
        <div className="box wrapper">
          <h3>
            {link.icon}
            {link.text}
          </h3>
        </div>
      </a>
    ));
};

function Menu() {
  const classes = useStyles();
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState("");
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 480px)",
  });
  const linksMarkup = useMemo(
    () => renderLinks(userInfo, classes),
    [userInfo, classes]
  );

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info.groups);
      });
    }
  }, [authState, oktaAuth]);

  return (
    <>
      {isMobileDevice ? (
        <div className={classes.mobileWelcomeBlock}>{linksMarkup}</div>
      ) : (
        <div className={classes.toolbar}>
          <div className={classes.welcomeBlock}>
            <div className="box-container-new">{linksMarkup}</div>
          </div>
        </div>
      )}
    </>
  );
}

export default Menu;
