import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import "./LiveBedCapacity.css";
import "./Table.css";
import TotalAvailableBeds from "./TotalAvailableBeds";
import CustomTable from "../../services/CustomTable";
import PercentageChangeStyles from "../../services/PercentageChangeStyles";
import PercentageStyles from "../../services/PercentageStyles";
import TrendHeaderText from "../../services/TrendHeaderText";
import CustomBarChart from "../../services/CustomBarChart";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import HospitalInfoTooltip from "../../services/HospitalDetails";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "#2F5E8C",
  },
  text: {
    marginLeft: "-20px",
    fontStyle: "bold",
  },
  icon: {
    color: "#2F5E8C",
    marginLeft: "-10px",
  },
 tabs: {
  },
});

function a11yProps(index) {
  return {
    id: `full-width-tabpanel-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    maxWidth: 300,
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
    lineHeight: 2,
  },
}));
const calculateTotal = (rows, accessor) => {
  return rows.reduce((sum, row) => {
    const value = row.values[accessor];
    // Check if the accessor exists in the row and its value is not null or "-"
    if (!value || value === null || value === "-") {
      return sum; // Ignore this value
    }
    return sum + value; // Add the value to the sum
  }, 0);
};

export default function PediatricBedsGroup({ data, days }) {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const commanColumns = [
    {
      // first group - TV Show
      Header: "Hospital Name",
      accessor: "HOSPITAL_NAME",
      disableFilters: true,
      Footer: "Total",
      Cell: (row) => {
        return (
          <div>
            <HospitalInfoTooltip row={row} />
          </div>
        );
      },
    },
    {
      Header: "Trauma Levels",
      accessor: "TRAUMA_LEVEL",
      disableFilters: true,
      Footer: "",
    },
    {
      Header: "Hospital Type",
      accessor: "HOSPITAL_TYPE",
      disableFilters: true,
      Footer: "",
    },
    {
      Header: "NMDS",
      accessor: "NMDS",
      disableFilters: true,
      Footer: "",
    },
    {
      Header: "Hospital City",
      accessor: "HQ_CITY",
      disableFilters: true,
      Footer: "",
    },
    {
      Header: "Hospital County",
      accessor: "COUNTY_NAME",
      disableFilters: true,
      show: false,
      Footer: "",
    },
    {
      Header: "Hospital Zip",
      accessor: "HQ_ZIP_CODE",
      disableFilters: true,
      Footer: "",
    },
    {
      Header: "AHA Number",
      accessor: "Facility_AHA_ID",
      disableFilters: true,
      Footer: "",
    },
    {
      Header: "RMCC",
      accessor: "RMCC",
      disableFilters: true,
      show: false,
      Footer: "",
    },
    {
      Header: "HCC",
      accessor: "HCC",
      disableFilters: true,
      show: false,
      Footer: "",
    },
  ];
  const columnsPedsICU = useMemo(
    (data) => [
      ...commanColumns,
      {
        Header: `Pediatric Intensive Care(PICU) ${
          days === 1 || days == null
            ? ""
            : "(Last " + days + " days of data)"
        }`,
        tipText: `Pediatric Intensive Care beds. Beds displaying here have been designated on the Bed Roster as ‘Peds
        ICU Beds’. Does not include ER Beds. Does not include Neonatal Incentive Care (NICU) or Newborn
        Beds.`,
        Footer: "",
        columns: [
          {
            Header: "Physically Available",
            accessor: "Total_Peds_ICU_Licensed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Staffed",
            accessor: "Total_Peds_ICU_Staffed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Unstaffed",
            accessor: "Total_Peds_ICU_Unstaffed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Occupied",
            accessor: "Total_Peds_ICU_Occupied_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Vacant/ Available",
            accessor: "Total_Peds_ICU_Available_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Physical Capacity",
            tipText: `Ratio of Occupied Beds to Physically Available Beds`,
            accessor: "Total_Peds_ICU_Percentage_Occupied",
            disableFilters: true,
            Cell: ({ value }) => PercentageStyles(value),
            Footer: "",
          },
          {
            Header: "Physical Capacity % Change",
            accessor: "Total_Peds_ICU_Percentage_Occupied_Change",
            disableFilters: true,
            Cell: ({ value }) => PercentageChangeStyles(value),
            Footer: "",
          },
          {
            Header: "Staffed Capacity",
            tipText: `Ratio of Occupied Beds to Staffed Bed`,
            accessor: "Total_Peds_ICU_Staffed_Percentage_Occupied",
            disableFilters: true,
            Cell: ({ value }) => PercentageStyles(value),
            Footer: "",
          },
          {
            Header: "Staffed Capacity % Change",
            accessor: "Total_Peds_ICU_Staffed_Percentage_Occupied_Change",
            disableFilters: true,
            Cell: ({ value }) => PercentageChangeStyles(value),
            Footer: "",
          },
          {
            id: "trend",
            disableSortBy: true,
            Header: () => <TrendHeaderText days={days} />,
            // Header: "14 Days Trend - Physical & Staffed Capacity",
            tipText: `${
              days === 1 || days == null ? "14 " : days + " "
            }Days Trend - Physical & Staffed Capacity `,            accessor: "chartData",
            disableFilters: true,
            Cell: ({ cell }) => (
              <CustomBarChart
                cell={cell}
                physicalDataField="Peds_ICU_Percentage_Occupied_Chart"
                staffedDataField="Peds_ICU_Staffed_Percentage_Occupied_Chart"
              />
            ),
            Footer: "",
          },
        ],
      },
    ],
    []
  );
  const columnsPedsMedSurg = useMemo(
    (data) => [
      ...commanColumns,
      {
        // Second group - Details
        Header: `Pediatric Medical/Surgical ${
          days === 1 || days == null
            ? ""
            : "(Last " + days + " days of data)"
        }`,
        tipText: `Inpatient beds used for Pediatric Medical/Surgical care. Beds displaying here have been designated on
        the bed roster as ‘Peds Beds’. Does not include Pediatric Intensive Care (PICU), Neonatal Intensive Care
        (NICU) or Newborn beds.`,
        Footer: "",
        // Second group columns
        columns: [
          {
            Header: "Physically Available",
            accessor: "Total_Pediatric_Licensed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Staffed",
            accessor: "Total_Pediatric_Staffed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Unstaffed",
            accessor: "Total_Pediatric_Unstaffed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Occupied",
            accessor: "Total_Pediatric_Occupied_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Vacant/ Available",
            accessor: "Total_Pediatric_Available_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Physical Capacity",
            tipText: `Ratio of Occupied Beds to Physically Available Beds`,
            accessor: "Total_Pediatric_Percentage_Occupied",
            disableFilters: true,
            Cell: ({ value }) => PercentageStyles(value),
            Footer: "",
          },
          {
            Header: "Physical Capacity % Change",
            accessor: "Total_Pediatric_Percentage_Occupied_Change",
            disableFilters: true,
            Cell: ({ value }) => PercentageChangeStyles(value),
            Footer: "",
          },
          {
            Header: "Staffed Capacity",
            tipText: `Ratio of Occupied Beds to Staffed Bed`,
            accessor: "Total_Pediatric_Staffed_Percentage_Occupied",
            disableFilters: true,
            Cell: ({ value }) => PercentageStyles(value),
            Footer: "",
          },
          {
            Header: "Staffed Capacity % Change",
            accessor: "Total_Pediatric_Staffed_Percentage_Occupied_Change",
            disableFilters: true,
            Cell: ({ value }) => PercentageChangeStyles(value),
            Footer: "",
          },
          {
            id: "trend",
            disableSortBy: true,
            Header: () => <TrendHeaderText days={days} />,
            // Header: "14 Days Trend - Physical & Staffed Capacity",
            tipText: `${
              days === 1 || days == null ? "14 " : days + " "
            }Days Trend - Physical & Staffed Capacity `,            accessor: "chartData",
            disableFilters: true,
            Cell: ({ cell }) => (
              <CustomBarChart
                cell={cell}
                physicalDataField="Pediatric_Percentage_Occupied_Chart"
                staffedDataField="Pediatric_Staffed_Percentage_Occupied_Chart"
              />
            ),
            Footer: "",
          },
        ],
      },
    ],
    []
  );

  const columnsNeonatal = useMemo(
    (data) => [
      ...commanColumns,
      {
        // Third group - Details
        Header: `Neonatal (NICU) ${
          days === 1 || days == null
            ? ""
            : "(Last " + days + " days of data)"
        }`,
        tipText:
          "Neonatal Intensive Care Unit beds. Beds displaying here have been designated on the bed roster as ‘Neonatal Beds (NICU)’. Does not include Pediatric Intensive Care (PICU) or Newborn beds.",
        Footer: "",
        // Third group columns
        columns: [
          {
            Header: "Physically Available",
            accessor: "Total_Neonatal_Licensed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Staffed",
            accessor: "Total_Neonatal_Staffed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Unstaffed",
            accessor: "Total_Neonatal_Unstaffed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Occupied",
            accessor: "Total_Neonatal_Occupied_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Vacant/ Available",
            accessor: "Total_Neonatal_Available_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Physical Capacity",
            tipText: `Ratio of Occupied Beds to Physically Available Beds`,
            accessor: "Total_Neonatal_Percentage_Occupied",
            disableFilters: true,
            Footer: "",
            Cell: ({ value }) => PercentageStyles(value),
          },
          {
            Header: "Physical Capacity % Change",
            accessor: "Total_Neonatal_Percentage_Occupied_Change",
            disableFilters: true,
            Footer: "",
            Cell: ({ value }) => PercentageChangeStyles(value),
          },
          {
            Header: "Staffed Capacity",
            tipText: `Ratio of Occupied Beds to Staffed Bed`,
            accessor: "Total_Neonatal_Staffed_Percentage_Occupied",
            disableFilters: true,
            Footer: "",
            Cell: ({ value }) => PercentageStyles(value),
          },
          {
            Header: "Staffed Capacity % Change",
            accessor: "Total_Neonatal_Staffed_Percentage_Occupied_Change",
            disableFilters: true,
            Footer: "",
            Cell: ({ value }) => PercentageChangeStyles(value),
          },
          {
            id: "trend",
            disableSortBy: true,
            Header: () => <TrendHeaderText days={days} />,
            // Header: "14 Days Trend - Physical & Staffed Capacity",
            tipText: `${
              days === 1 || days == null ? "14 " : days + " "
            }Days Trend - Physical & Staffed Capacity `,            accessor: "chartData",
            disableFilters: true,
            Cell: ({ cell }) => (
              <CustomBarChart
                cell={cell}
                physicalDataField="Neonatal_Percentage_Occupied_Chart"
                staffedDataField="Neonatal_Staffed_Percentage_Occupied_Chart"
              />
            ),
            Footer: "",
          },
        ],
      },
    ],
    []
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log(days);
  return (
    <div>
      <Box className="sticky-thc-tabs" sx={{ borderBottom: 1, borderColor: "divider"}}>
        <Tabs
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="inherit"
          variant="scrollable"
        >
          <Tab
            sx={{
              borderRight: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            label="Pediatric Intensive Care(PICU)"
            {...a11yProps(0)}
          />

          <Tab
            sx={{
              borderRight: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            label="Pediatric Medical/Surgical"
            {...a11yProps(1)}
          />
                    <Tab
            sx={{
              borderRight: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            label="Neonatal (NICU)"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <Box sx={{ width: "100%" }}>
        <TabPanel value={value} index={0}>
          <TotalAvailableBeds beds={data} days={days} type="Total_Peds_ICU_" />
          <CustomTable data={data} days={days} columns={columnsPedsICU} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TotalAvailableBeds beds={data} type="Total_Pediatric_" />
          <CustomTable data={data} days={days} columns={columnsPedsMedSurg} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TotalAvailableBeds beds={data} type="Total_Neonatal_" />
          <CustomTable data={data} days={days} columns={columnsNeonatal} />
        </TabPanel>
      </Box>
    </div>
  );
}
