import { BarChart } from '@mui/x-charts';
import React from 'react';
import PercentageStyles from './PercentageStyles'; // Import your PercentageStyles function

const CustomBarChart = ({ cell, physicalDataField, staffedDataField }) => {
  if (!cell.value) return null;

  return (
    <div style={{textAlign:'center'}}>
    <BarChart
      margin={{ top: 0, bottom: 0, left:5, right: 5 }}
      leftAxis={null}
      bottomAxis={null}
      xAxis={[
        {
          scaleType: "band",
          valueFormatter: (element) => (
            <span style={{ color: "white", fontWeight: "bold" }}>
              Date : {element}
            </span>
          ),
          data: cell.value.map((item) => item.Date),
        },
      ]}
      series={[
        {
          data: cell.value.map((item) => item[physicalDataField]),
          label: "Physical Capacity",
          valueFormatter: (element) => PercentageStyles(element),
        },
        {
          data: cell.value.map((item) => item[staffedDataField]),
          label: "Staffed Capacity",
          valueFormatter: (element) => PercentageStyles(element),
        },
      ]}
      width={300}
      colors={["#547ea5", "#9ec9d3"]}
      height={60}
      slotProps={{ legend: { hidden: true } }}
      tooltip={{
        trigger: "axis",
        formatter: (params) => {
          const date = params[0].axisValue;
          const physicalCapacity = params[0].value;
          const staffedCapacity = params[1].value;
          return `Date: ${date}<br/>Physical Capacity: ${physicalCapacity}<br/>Staffed Capacity: ${staffedCapacity}`;
        },
      }}
    />
    </div>
  );
};

export default CustomBarChart;
