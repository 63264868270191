// src/CardChart.js
import React from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Avatar,
} from "@mui/material";
import { LineChart, Line, ResponsiveContainer, Tooltip } from "recharts";
import TodayIcon from "@mui/icons-material/Today";
import { AlarmOff, AlarmRounded, EventBusy } from "@mui/icons-material";
import dayjs from "dayjs";
// [
//   {
//     day_of_last_update: 22,
//     facilitycode: "IA-ACMH-HOSP",
//     facilityname: "AUDUBON COUNTY MEMORIAL HOSPITAL & CLINICS",
//     last_update_by_hour: 19,
//     last_updated_by: "Melanie",
//     lastupdatedon: "2024-02-22",
//     max_last_update_timestamp: "2024-02-22T19:41:08",
//     number_of_updates_by_day: 12,
//     number_of_updates_by_day_wk: 12,
//     number_of_updates_by_hour: 12,
//     rundate_timestamp: "2024-05-16T15:00:00.029772",
//     weekday_of_lastupdate: "Thursday",
//   },
// ];

const data = [
  { name: "Jan", uv: 4000 },
  { name: "Feb", uv: 3000 },
  { name: "Mar", uv: 2000 },
  { name: "Apr", uv: 2780 },
  { name: "May", uv: 1890 },
  { name: "Jun", uv: 2390 },
  { name: "Jul", uv: 3490 },
  { name: "Jan", uv: 4000 },
  { name: "Feb", uv: 3000 },
  { name: "Mar", uv: 2000 },
  { name: "Apr", uv: 2780 },
  { name: "May", uv: 1890 },
  { name: "Jun", uv: 2390 },
  { name: "Jul", uv: 3490 },
  
];

const DashboardCard = ({ icon, title, value, percentage, trend }) => (
  <Card sx={{ borderRadius: "10px" }}>
    <CardContent>
      <Grid container spacing={2}>
        <Grid item>
          {" "}
          <Avatar sx={{ bgcolor: trend }}>{icon}</Avatar>
        </Grid>
        <Grid item xs >
          <Typography variant="body2">{title}</Typography>
          <Typography variant="h4">{value}</Typography>
        </Grid>
        <Grid item justifyContent='bottom'>
          {/* <ResponsiveContainer width={100} height={40}>
            <LineChart data={data}>
              <Line
                type="monotone"
                dataKey="uv"
                stroke={trend}
                strokeWidth={2}
                dot={false}
              />
              <Tooltip />
            </LineChart>
            <Box sx={{ textAlign: "right", pt:2 }}>
              <Typography
                variant="body2"
                color={percentage >= 0 ? "green" : "red"}
              >
                {percentage >= 0 ? `+${percentage}%` : `${percentage}%`}
              </Typography>
              <Typography variant="body2"> this month</Typography>
            </Box>
          </ResponsiveContainer> */}
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

const CardChart2 = ({updatedToday, updatedLast4hours, notUpdatedToday, notUpdatedLast4hours, selectedDateRange}) => (
  <Box pt={1}>
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6} md={3}>
        <DashboardCard
          icon={<TodayIcon style={{ fontSize: 20 }} />}
          title={selectedDateRange[0] !== null ? "Hospitals Updated" : "Hospitals Updated Today"}
          value={updatedToday}
          percentage={40}
          trend="#0E4D97"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <DashboardCard
          icon={<AlarmRounded style={{ fontSize: 20 }} />}
          title="Hospitals Updated (Last 4 Hours)"
          value={updatedLast4hours}
          percentage={25}
          trend="#83BE40"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <DashboardCard
          icon={<EventBusy style={{ fontSize: 20 }} />}
          title={selectedDateRange[0] !== null ? "Hospitals Not Updated" : "Hospitals Not Updated Today"}
          value={notUpdatedToday}
          percentage={-12}
          trend="#F3AA1B"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <DashboardCard
          icon={<AlarmOff style={{ fontSize: 20 }} />}
          title="Hospitals Not Updated (Last 4 Hours)"
          value={notUpdatedLast4hours}
          percentage={-12}
          trend="#09AED9"
        />
      </Grid>
    </Grid>
  </Box>
);

export default CardChart2;
