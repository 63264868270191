import React from "react";
import ProgressBar from "./ProgressBar";

const PercentageStyles = (value) => {
  const value1 = parseInt(value);
  if (value === 0) {
    return "0%";
  }
  if (value === "-" || value == null || value == 0) {
    return "-";
  } else if (Math.floor(value1) >= 80 && Math.floor(value1) <= 100) {
    const pValueER = Math.round(value1);
    return <ProgressBar bgcolor="#DC5C5A" completed={pValueER}></ProgressBar>;
  } else if (Math.floor(value1) > 100) {
    const pValueER = Math.round(value1);
    return (
      <>
        {" "}
        <ProgressBar bgcolor="#FF7F7F" completed={pValueER}></ProgressBar>
        <WarningAmberIcon style={{ color: "white", fontSize: "15px" }} />
      </>
    );
  } else if (Math.floor(value1) >= 60 && Math.floor(value1) < 80) {
    const pValueER = Math.round(value1);
    return (
      <>
        <ProgressBar bgcolor="#F3AA1B" completed={pValueER}></ProgressBar>
      </>
    );
  } else {
    const pValueER = Math.round(value1);
    return (
      <>
        <ProgressBar bgcolor="#008001" completed={pValueER}></ProgressBar>
      </>
    );
  }
};
export default PercentageStyles;
