import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import "./LiveBedCapacity.css";
import "./Table.css";
import TotalAvailableBeds from "./TotalAvailableBeds";
import CustomTable from "../../services/CustomTable";
import PercentageChangeStyles from "../../services/PercentageChangeStyles";
import PercentageStyles from "../../services/PercentageStyles";
import TrendHeaderText from "../../services/TrendHeaderText";
import CustomBarChart from "../../services/CustomBarChart";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import HospitalInfoTooltip from "../../services/HospitalDetails";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "#2F5E8C",
  },
  text: {
    marginLeft: "-20px",
    fontStyle: "bold",
  },
  icon: {
    color: "#2F5E8C",
    marginLeft: "-10px",
  },
  tabs: {},
});

function a11yProps(index) {
  return {
    id: `full-width-tabpanel-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    maxWidth: 300,
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
    lineHeight: 2,
  },
}));
const calculateTotal = (rows, accessor) => {
  return rows.reduce((sum, row) => {
    const value = row.values[accessor];
    // Check if the accessor exists in the row and its value is not null or "-"
    if (!value || value === null || value === "-") {
      return sum; // Ignore this value
    }
    return sum + value; // Add the value to the sum
  }, 0);
};

export default function OverviewGroup({ data, days }) {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const commanColumns = [
    {
      // first group - TV Show
      Header: "Hospital Name",
      accessor: "HOSPITAL_NAME",
      disableFilters: true,
      Footer: "Total",
      Cell: (row) => {
        return (
          <div>
            <HospitalInfoTooltip row={row} />
          </div>
        );
      },
    },
    {
      Header: "Trauma Levels",
      accessor: "TRAUMA_LEVEL",
      disableFilters: true,
      Footer: "",
    },
    {
      Header: "Hospital Type",
      accessor: "HOSPITAL_TYPE",
      disableFilters: true,
      Footer: "",
    },
    {
      Header: "NMDS",
      accessor: "NMDS",
      disableFilters: true,
      Footer: "",
    },
    {
      Header: "Hospital City",
      accessor: "HQ_CITY",
      disableFilters: true,
      Footer: "",
    },
    {
      Header: "Hospital County",
      accessor: "COUNTY_NAME",
      disableFilters: true,
      show: false,
      Footer: "",
    },
    {
      Header: "Hospital Zip",
      accessor: "HQ_ZIP_CODE",
      disableFilters: true,
      Footer: "",
    },
    {
      Header: "AHA Number",
      accessor: "Facility_AHA_ID",
      disableFilters: true,
      Footer: "",
    },
    {
      Header: "RMCC",
      accessor: "RMCC",
      disableFilters: true,
      show: false,
      Footer: "",
    },
    {
      Header: "HCC",
      accessor: "HCC",
      disableFilters: true,
      show: false,
      Footer: "",
    },
  ];
  const columnsInpatient = useMemo(
    (data) => [
      ...commanColumns,
      {
        Header: `Inpatient ${
          days === 1 || days == null ? "" : "(Last " + days + " days of data)"
        }`,
        tipText: `Inpatient beds which include Adult Medical Surgical, Pediatric Medical/Surgical, Adult ICU and Pediatric
        Intensive Care (PICU), Psychiatric, Labor and Delivery. Does not include Newborn Nursery and Neonatal
        ICU (NICU) Beds`,
        Footer: "",
        columns: [
          {
            Header: "Physically Available",
            accessor: "Total_Licensed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Staffed",
            accessor: "Total_Staffed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Unstaffed",
            accessor: "Total_Unstaffed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Occupied",
            accessor: "Total_Occupied_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
            
          },

          {
            Header: "Vacant/ Available",
            accessor: "Total_Available_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },

          {
            id: "Physical Capacity",
            Header: "Physical Capacity",
            tipText: `Ratio of Occupied Beds to Physically Available Beds`,
            accessor: "Percentage_Occupied",
            disableFilters: true,
            Cell: ({ value }) => PercentageStyles(value),
            Footer: "",
          },
          {
            Header: "Physical Capacity % Change",
            accessor: "Percentage_Occupied_Change",
            disableFilters: true,
            Cell: ({ value }) => PercentageChangeStyles(value),
            Footer: "",
          },
          {
            id: "Staffed Capacity",
            Header: "Staffed Capacity",
            tipText: `Ratio of Occupied Beds to Staffed Bed`,
            accessor: "Staffed_Percentage_Occupied",
            disableFilters: true,
            Cell: ({ value }) => PercentageStyles(value),
            Footer: "",
          },
          {
            Header: "Staffed Capacity % Change",
            accessor: "Staffed_Percentage_Occupied_Change",
            disableFilters: true,
            Cell: ({ value }) => PercentageChangeStyles(value),
            Footer: "",
          },
          {
            id: "trend",
            disableSortBy: true,
            Header: () => <TrendHeaderText days={days} />,
            // Header: "14 Days Trend - Physical & Staffed Capacity",
            tipText: `${
              days === 1 || days == null ? "14 " : days + " "
            }Days Trend - Physical & Staffed Capacity `,
            accessor: "chartData",
            disableFilters: true,
            Cell: ({ cell }) => (
              <CustomBarChart
                cell={cell}
                physicalDataField="Overview_Percentage_Occupied_Chart"
                staffedDataField="Overview_Staffed_Percentage_Occupied_Chart"
              />
            ),
            Footer: "",
          },
        ],
      },
    ],
    []
  );
  const columnsICU = useMemo(
    (data) => [
      ...commanColumns,
      {
        Header: `ICU – Adult and Pediatric ${
          days === 1 || days == null ? "" : "(Last " + days + " days of data)"
        }`,
        tipText: `Inpatient beds which include Adult ICU and Pediatric ICU (PICU). Does not inclue Neonatal ICU (NICU)`,
        Footer: "",
        columns: [
          {
            Header: "Physically Available",
            disableFilters: true,
            accessor: "Total_ICU_Licensed_Beds",
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Staffed",
            accessor: "Total_ICU_Staffed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Unstaffed",
            accessor: "Total_ICU_Unstaffed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Occupied",
            accessor: "Total_ICU_Occupied_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Vacant/ Available",
            accessor: "Total_ICU_Available_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Physical Capacity",
            tipText: `Ratio of Occupied Beds to Physically Available Beds`,
            accessor: "Total_ICU_Percentage_Occupied",
            disableFilters: true,
            Cell: ({ value }) => PercentageStyles(value),
            Footer: "",
          },
          {
            Header: "Physical Capacity % Change",
            accessor: "Total_ICU_Percentage_Occupied_Change",
            disableFilters: true,
            Cell: ({ value }) => PercentageChangeStyles(value),
            Footer: "",
          },
          {
            Header: "Staffed Capacity",
            tipText: `Ratio of Occupied Beds to Staffed Bed`,
            accessor: "Total_ICU_Staffed_Percentage_Occupied",
            disableFilters: true,
            Cell: ({ value }) => PercentageStyles(value),
            Footer: "",
          },
          {
            Header: "Staffed Capacity % Change",
            accessor: "Total_ICU_Staffed_Percentage_Occupied_Change",
            disableFilters: true,
            Cell: ({ value }) => PercentageChangeStyles(value),
            Footer: "",
          },
          {
            id: "trend",
            disableSortBy: true,
            Header: () => <TrendHeaderText days={days} />,
            // Header: "14 Days Trend - Physical & Staffed Capacity",
            tipText: `${
              days === 1 || days == null ? "14 " : days + " "
            }Days Trend - Physical & Staffed Capacity `,            accessor: "chartData",
            disableFilters: true,
            Cell: ({ cell }) => (
              <CustomBarChart
                cell={cell}
                physicalDataField="ICU_Percentage_Occupied_Chart"
                staffedDataField="ICU_Staffed_Percentage_Occupied_Chart"
              />
            ),
            Footer: "",
          },
        ],
      },
    ],
    []
  );
  const columnsER = useMemo(
    (data) => [
      ...commanColumns,
      {
        Header: "ER",
        tipText: `Emergency Department Beds`,
        Footer: "",
        columns: [
          {
            Header: "Physically Available",
            accessor: "Total_ER_Licensed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Staffed",
            accessor: "Total_ER_Staffed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Unstaffed",
            accessor: "Total_ER_Unstaffed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Occupied",
            accessor: "Total_ER_Occupied_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Vacant/ Available",
            accessor: "Total_ER_Available_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Physical Capacity",
            tipText: `Ratio of Occupied Beds to Physically Available Beds`,
            accessor: "Total_ER_Percentage_Occupied",
            disableFilters: true,
            Footer: "",
            Cell: ({ value }) => PercentageStyles(value),
          },
          {
            Header: "Physical Capacity % Change",
            accessor: "Total_ER_Percentage_Occupied_Change",
            disableFilters: true,
            Footer: "",
            Cell: ({ value }) => PercentageChangeStyles(value),
          },
          {
            Header: "Staffed Capacity",
            tipText: `Ratio of Occupied Beds to Staffed Bed`,
            accessor: "Total_ER_Staffed_Percentage_Occupied",
            disableFilters: true,
            Footer: "",
            Cell: ({ value }) => PercentageStyles(value),
          },
          {
            Header: "Staffed Capacity % Change",
            accessor: "Total_ER_Staffed_Percentage_Occupied_Change",
            disableFilters: true,
            Footer: "",
            Cell: ({ value }) => PercentageChangeStyles(value),
          },
          {
            id: "trend",
            disableSortBy: true,
            Header: () => <TrendHeaderText days={days} />,
            // Header: "14 Days Trend - Physical & Staffed Capacity",
            tipText: `${
              days === 1 || days == null ? "14 " : days + " "
            }Days Trend - Physical & Staffed Capacity `,            
            accessor: "chartData",

            disableFilters: true,
            Cell: ({ cell }) => (
              <CustomBarChart
                cell={cell}
                physicalDataField="ER_Percentage_Occupied_Chart"
                staffedDataField="ER_Staffed_Percentage_Occupied_Chart"
              />
            ),
            Footer: "",
          },
        ],
      },
    ],
    []
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log(days);
  return (
    <div>
      <Box
        className="sticky-thc-tabs"
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tabs
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="inherit"
          variant="scrollable"
        >
          <Tab
            sx={{
              borderRight: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            label="Inpatient"
            {...a11yProps(0)}
          />

          <Tab
            sx={{
              borderRight: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            label="ICU"
            {...a11yProps(1)}
          />
          <Tab
            sx={{
              borderRight: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            label="ER"
            {...a11yProps(2)}
          />

          {/* <Tab
            sx={{
              borderRight: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            label="Male"
            {...a11yProps(2)}
          />
          <Tab
            sx={{
              borderRight: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            label="Female"
            {...a11yProps(3)}
          /> */}
        </Tabs>
      </Box>
      <Box sx={{ width: "100%" }}>
        <TabPanel value={value} index={0}>
          <TotalAvailableBeds
            beds={data}
            days={days}
            type="Overview_"
            type2="ER_"
            tab="Overview"
          />
          <CustomTable data={data} days={days} columns={columnsInpatient} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TotalAvailableBeds beds={data} type="Total_ICU_" />
          <CustomTable data={data} days={days} columns={columnsICU} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TotalAvailableBeds beds={data} type="Total_ER_" />
          <CustomTable data={data} days={days} columns={columnsER} />
        </TabPanel>
        {/* <TabPanel value={value} index={2}>
          <TotalAvailableBeds beds={data} type="Total_Psych_Male_" />
          <PsychiatryMaleBedsTable data={data} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TotalAvailableBeds beds={data} type="Total_Psych_Female_" />
          <PsychiatryFemaleBedsTable data={data} />
        </TabPanel> */}
      </Box>
    </div>
  );
}
