import React, { useState, useRef, useEffect } from "react";
import HotelIcon from "@mui/icons-material/HotelRounded";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@mui/styles";
import "./LiveBedCapacity.css";
import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";
import { Grid, List, Stack, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import ProgressBar from "../../services/ProgressBar";
const useStyles = makeStyles({
  root: {
    height: "20px",
    color: "black",
  },
  text: {
    marginLeft: "-20px",
    color: "#2F5E8C",
  },
  icon: {
    color: "#2F5E8C",
    marginLeft: "-10px",
    fontSize: "25px",
  },
});

function TotalAvailableBeds({ beds, type, days }) {
  const classes = useStyles();
  const [totalStaffedBeds, setTotalStaffedBeds] = useState("");
  const [totalOccupiedBeds, setTotalOccupiedBeds] = useState("");
  const [totalBedsAvailable, setTotalBedsAvailable] = useState("");
  const [totalPhysicalPercentage, setTotalPhysicalPercentage] = useState("");
  const [totalPhysicalPercentage14days, setTotalPhysicalPercentage14days] =
    useState("");

  const [totalStaffedPercentage, setTotalStaffedPercentage] = useState("");
  const [totalStaffedPercentage14days, setTotalStaffedPercentage14days] =
    useState("");

  const [totalPhysicallyAvailableBeds, setTotalPhysicallyAvailableBeds] =
    useState("");
  const [totalUnstaffedBeds, setTotalUnstaffedBeds] = useState("");

  const countNonNullValues = (array, type, suffix) => {
    return array.reduce((count, item) => {
      const propertyName = `${type}${suffix}`;
      const value = item[propertyName];
      if (value != null && value !== "-" && value !== "") {
        return count + 1;
      }
      return count;
    }, 0);
  };

  const calculate = (beds, type, type2) => {
    const sumOfPhysicallyAvailableBeds =
      beds &&
      beds.reduce(function (accumulator, object) {
        const licensedBeds = object[`${type}Licensed_Beds`];

        if (licensedBeds != null && licensedBeds !== "-") {
          return accumulator + parseInt(licensedBeds);
        } else {
          return accumulator;
        }
      }, 0);

    setTotalPhysicallyAvailableBeds(sumOfPhysicallyAvailableBeds);

    const sumOfTotalStaffedBeds =
      beds &&
      beds.reduce(function (accumulator, object) {
        const licensedBeds = object[`${type}Staffed_Beds`];
        if (licensedBeds != null && licensedBeds !== "-") {
          return accumulator + parseInt(licensedBeds);
        } else {
          return accumulator;
        }
      }, 0);
    setTotalStaffedBeds(sumOfTotalStaffedBeds);

    const sumOfTotalUnstaffedBeds =
      beds &&
      beds.reduce(function (accumulator, object) {
        const licensedBeds = object[`${type}Unstaffed_Beds`];
        if (licensedBeds != null && licensedBeds !== "-") {
          return accumulator + parseInt(licensedBeds);
        } else {
          return accumulator;
        }
      }, 0);
    setTotalUnstaffedBeds(sumOfTotalUnstaffedBeds);

    const sumOfOccupiedBeds =
      beds &&
      beds.reduce(function (accumulator, object) {
        const occupiedBeds = object[`${type}Occupied_Beds`];
        if (occupiedBeds != null && occupiedBeds !== "-") {
          return accumulator + parseInt(occupiedBeds);
        } else {
          return accumulator;
        }
      }, 0);

    setTotalOccupiedBeds(sumOfOccupiedBeds);

    const sumOfAvailableBeds =
      beds &&
      beds.reduce(function (accumulator, object) {
        const availableBeds = object[`${type}Available_Beds`];
        if (availableBeds != null && availableBeds !== "-") {
          return accumulator + parseInt(availableBeds);
        } else {
          return accumulator;
        }
      }, 0);
    setTotalBedsAvailable(sumOfAvailableBeds);

    const nonNullCountPo = countNonNullValues(
      beds,
      type,
      "Percentage_Occupied"
    );

    const totalPercentageOccupied = beds.reduce((accumulator, bed) => {
      const percentageOccupied = bed[`${type}Percentage_Occupied`];

      if (
        percentageOccupied != null &&
        percentageOccupied !== "-" &&
        percentageOccupied !== ""
      ) {
        return accumulator + parseFloat(percentageOccupied);
      } else {
        return accumulator;
      }
    }, 0);
    const averagePercentageOccupied = totalPercentageOccupied / nonNullCountPo;
  
    setTotalPhysicalPercentage(averagePercentageOccupied.toFixed(2));

    const nonNullCountPo14 = countNonNullValues(
      beds,
      type,
      "Percentage_Occupied_Change"
    );

    const totalPercentageOccupied14Days = beds.reduce((accumulator, bed) => {
      const percentageOccupied14Days = bed[`${type}Percentage_Occupied_Change`];
      if (
        percentageOccupied14Days != null &&
        percentageOccupied14Days !== "-" &&
        percentageOccupied14Days !== ""
      ) {
        return accumulator + parseFloat(percentageOccupied14Days);
      } else {
        return accumulator;
      }
    }, 0);

    const averagePercentageOccupied14Days =
      totalPercentageOccupied14Days / nonNullCountPo14;
    setTotalPhysicalPercentage14days(averagePercentageOccupied14Days);

    const nonNullCountSpo = countNonNullValues(
      beds,
      type,
      "Staffed_Percentage_Occupied"
    );

    const totalStaffedPercentageOccupied = beds.reduce((accumulator, bed) => {
      const percentageOccupied = bed[`${type}Staffed_Percentage_Occupied`];
      if (
        percentageOccupied != null &&
        percentageOccupied !== "-" &&
        percentageOccupied !== ""
      ) {
        return accumulator + parseFloat(percentageOccupied);
      } else {
        return accumulator;
      }
    }, 0);

    const averageStaffedPercentageOccupied =
      totalStaffedPercentageOccupied / nonNullCountSpo;
    setTotalStaffedPercentage(averageStaffedPercentageOccupied.toFixed(2));

    const nonNullCountSpo14 = countNonNullValues(
      beds,
      type,
      "Staffed_Percentage_Occupied_Change"
    );

    const totalStaffedPercentageOccupied14Days = beds.reduce(
      (accumulator, bed) => {
        const percentageOccupied =
          bed[`${type}Staffed_Percentage_Occupied_Change`];
        if (
          percentageOccupied != null &&
          percentageOccupied !== "-" &&
          percentageOccupied !== ""
        ) {
          return accumulator + parseFloat(percentageOccupied);
        } else {
          return accumulator;
        }
      },
      0
    );

    // Calculate average based on non-null beds
    const averageStaffedPercentageOccupied14Days =
      totalStaffedPercentageOccupied14Days / nonNullCountSpo14;

    setTotalStaffedPercentage14days(averageStaffedPercentageOccupied14Days);
  };

  useEffect(() => {
    calculate(beds, type);
  }, [beds, type]);

  const getCellValue = (value, newValue) => {
    newValue = Math.abs(newValue).toFixed(2);
    const isNegative = Math.sign(newValue);
    if (value === 0) {
      return "0%";
    }
    if (value === "-" || value == null || value == 0) {
      return "-";
    } else if (Math.floor(value) >= 80 && Math.floor(value) <= 100) {
      const pValueER = value;
      return (
        <>
          <ProgressBar bgcolor="#DC5C5A" completed={pValueER} />
          <Tooltip title={newValue + "%"} placement="right" arrow>
            <Stack style={{ display: "inline-block" }} direction="row">
              <span
                style={{
                  marginRight: "-5px",
                  color:
                    isNegative !== -1
                      ? "red"
                      : isNegative == -1
                      ? "green"
                      : "black",
                }}
              >
                {" "}
                {isNegative !== -1 ? (
                  <ArrowUpward style={{ fontSize: "15px" }} />
                ) : isNegative == -1 ? (
                  <ArrowDownward style={{ fontSize: "15px" }} />
                ) : (
                  ""
                )}{" "}
              </span>
              <Typography
                variant="body2"
                style={{
                  display: "inline",
                  color:
                    isNegative !== -1
                      ? "red"
                      : isNegative == -1
                      ? "green"
                      : "black",
                }}
              >
                {" "}
                {newValue}%
              </Typography>
            </Stack>
          </Tooltip>
        </>
      );
    } else if (Math.floor(value) > 100) {
      const pValueER = Math.round(value);
      return (
        <>
          <>
            <ProgressBar bgcolor="#DC5C5A" completed={pValueER} />
            <Tooltip title={newValue + "%"} placement="right" arrow>
              <Stack style={{ display: "inline-block" }} direction="row">
                <span
                  style={{
                    marginRight: "-5px",
                    color:
                      isNegative !== -1
                        ? "red"
                        : isNegative == -1
                        ? "green"
                        : "black",
                  }}
                >
                  {" "}
                  {isNegative !== -1 ? (
                    <ArrowUpward style={{ fontSize: "15px" }} />
                  ) : isNegative == -1 ? (
                    <ArrowDownward style={{ fontSize: "15px" }} />
                  ) : (
                    ""
                  )}{" "}
                </span>
                <Typography
                  variant="body2"
                  style={{
                    display: "inline",
                    color:
                      isNegative !== -1
                        ? "red"
                        : isNegative == -1
                        ? "green"
                        : "black",
                  }}
                >
                  {" "}
                  {newValue}%
                </Typography>
              </Stack>
            </Tooltip>
          </>
          <WarningAmberIcon style={{ color: "white", fontSize: "15px" }} />
        </>
      );
    } else if (Math.floor(value) >= 60 && Math.floor(value) < 80) {
      const pValueER = Math.round(value);
      return (
        <>
          <ProgressBar bgcolor="#F3AA1B" completed={pValueER} />
          <Tooltip title={newValue + "%"} placement="right" arrow>
            <Stack style={{ display: "inline-block" }} direction="row">
              <span
                style={{
                  marginRight: "-5px",
                  color:
                    isNegative !== -1
                      ? "red"
                      : isNegative == -1
                      ? "green"
                      : "black",
                }}
              >
                {isNegative !== -1 ? (
                  <ArrowUpward style={{ fontSize: "15px" }} />
                ) : isNegative == -1 ? (
                  <ArrowDownward style={{ fontSize: "15px" }} />
                ) : (
                  ""
                )}{" "}
              </span>
              <Typography
                variant="body2"
                style={{
                  display: "inline",
                  color:
                    isNegative !== -1
                      ? "red"
                      : isNegative == -1
                      ? "green"
                      : "black",
                }}
              >
                {" "}
                {newValue}%
              </Typography>
            </Stack>
          </Tooltip>
        </>
      );
    } else {
      const pValueER = Math.round(value);
      return (
        <>
          <ProgressBar bgcolor="#008001" completed={pValueER} />
          <Tooltip title={newValue + "%"} placement="right" arrow>
            <Stack style={{ display: "inline-block" }} direction="row">
              <span
                style={{
                  marginRight: "-5px",
                  color:
                    isNegative !== -1
                      ? "red"
                      : isNegative == -1
                      ? "green"
                      : "black",
                }}
              >
                {" "}
                {isNegative !== -1 ? (
                  <ArrowUpward style={{ fontSize: "15px" }} />
                ) : isNegative == -1 ? (
                  <ArrowDownward style={{ fontSize: "15px" }} />
                ) : (
                  ""
                )}{" "}
              </span>
              <Typography
                variant="body2"
                style={{
                  display: "inline",
                  color:
                    isNegative !== -1
                      ? "red"
                      : isNegative == -1
                      ? "green"
                      : "black",
                }}
              >
                {" "}
                {newValue}%
              </Typography>
            </Stack>
          </Tooltip>
        </>
      );
    }
  };
  return (
    <div className="sticky-thc-totals">
      {/* Total beds card */}{" "}
      <Grid container spacing={1} padding={0}>
        {/* First Box */}
        <Grid item xs={12}>
          <h6 className="card-header bg-light">
            <List>
              <ListItem className={classes.root}>
                <ListItemIcon>
                  <HotelIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText disableTypography className={classes.text}>
                  TOTAL AVAILABLE BEDS
                </ListItemText>
                <div className="legend-box">
                  <div className="legend-labels">
                    <span>Legend:</span>
                    <div>
                      <span id="circle2"> &nbsp; &nbsp; </span> &nbsp;Greater
                      than or equal to 80%
                    </div>
                    <div>
                      <span id="circle3"> &nbsp; &nbsp; </span> &nbsp;between
                      60% to 79%
                    </div>
                    <div>
                      <span id="circle4"> &nbsp; &nbsp; </span> &nbsp;less than
                      60%
                    </div>
                  </div>
                </div>
              </ListItem>{" "}
            </List>
          </h6>{" "}
          <Grid container spacing={1}>
            <Grid item col sm>
              <div className="card-er">
                <div className="card-body">
                  <h2 className="card-title font-weight-bold">
                    {" "}
                    {totalPhysicallyAvailableBeds}
                  </h2>
                  <span className="card-text">Physically Available </span>
                </div>
              </div>
            </Grid>
            <Grid item col sm>
              <div className="card-er">
                <div className="card-body">
                  <h2 className="card-title font-weight-bold">
                    {" "}
                    {totalStaffedBeds}
                  </h2>
                  <span className="card-text">Staffed</span>
                </div>
              </div>
            </Grid>
            <Grid item col sm>
              <div className="card-er">
                <div className="card-body">
                  <h2 className="card-title font-weight-bold">
                    {" "}
                    {totalUnstaffedBeds}
                  </h2>
                  <span className="card-text ">Unstaffed</span>
                </div>
              </div>
            </Grid>
            <Grid item col sm>
              <div className="card-er">
                <div className="card-body">
                  <h2 className="card-title font-weight-bold">
                    {" "}
                    {totalOccupiedBeds}
                  </h2>
                  <span className="card-text">Occupied</span>
                </div>
              </div>
            </Grid>
            <Grid item col sm>
              <div className="card-er">
                <div className="card-body">
                  <h2 className="card-title font-weight-bold">
                    {" "}
                    {totalBedsAvailable}
                  </h2>
                  <span className="card-text">Vacant/Available</span>
                </div>
              </div>
            </Grid>
            <Grid item col sm>
              <div className="card-er">
                <div className="card-body">
                  <h2 className="card-title">
                    {" "}
                    {getCellValue(
                      totalPhysicalPercentage,
                      totalPhysicalPercentage14days
                    )}
                    {/* {percentageValueEditor(totalPhysicalPercentage)} */}
                  </h2>
                  <span className="card-text">Physical Capacity</span>
                </div>
              </div>
            </Grid>
            <Grid item col sm>
              <div className="card-er">
                <div className="card-body">
                  <h2 className="card-title">
                    {" "}
                    {getCellValue(
                      totalStaffedPercentage,
                      totalStaffedPercentage14days
                    )}
                    {/* {percentageValueEditor(totalStaffedPercentage)} */}
                  </h2>
                  <span className="card-text">
                    Staffed Capacity
                    <Tooltip
                      placement="left-start"
                      title={
                        <React.Fragment>
                          Staffed Capacity = Ratio of Occupied beds to Staffed
                          Beds. Note: This calculation will not be accurate
                          until after 4/1/24, when all facilities are expected
                          to have entered/reported their baseline “staffed”
                          beds.
                        </React.Fragment>
                      }
                    >
                      <span id="staffed-hover">*</span>
                    </Tooltip>
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default TotalAvailableBeds;
