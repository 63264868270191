import { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  MenuItem,
  FormControl,
  Typography,
  ListItem,
  ListItemText,
  Snackbar,
  Alert,
  AlertTitle,
} from "@mui/material";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import { useOktaAuth } from "@okta/okta-react";
import dayjs from "dayjs";
import { Box, Card, List } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import { listOfFacilities } from "../../services/Constants";
import LinearProgress from "@mui/material/LinearProgress";
import { ForwardToInboxRounded, Refresh } from "@mui/icons-material";
import { MdSyncAlt, MdForwardToInbox } from "react-icons/md";
import NotFound from "../../Alerts/NotFound";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles({
  textField: {
    border: "none",
  },
  root: {
    height: "30px",
    color: "#2F5E8C",
  },
  icon: {
    color: "#2F5E8C",
    marginRight: "7px",
    fontSize: "35px",
  },
  text: {
    marginLeft: "-10px",
    fontWeight: "bold",
    fontSize: "30px",
  },
});

function BedDataPanel() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [userGroups, setUserGroups] = useState(null);
  const [facilityNames, setFacilityNames] = useState(null);
  const [facilityName, setFacilityName] = useState(null);
  const [postSucceeded, setPostSucceeded] = useState(false);
  const [postFailed, setPostFailed] = useState(false);
  const { authState, oktaAuth } = useOktaAuth();

  const accessTokenResponse = authState.accessToken;
  const bearerToken = accessTokenResponse?.accessToken;

  const {
    register,
    handleSubmit,
    setValue,
    error,
    control,
    formState: { errors },
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "tableData",
  });

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
        setUserGroups(info.groups);
        setFacilityNames(info.facilityAdmins);
      });
    }
  }, [authState, oktaAuth]);

  const onSubmit = async (data) => {
    setPostSucceeded(false);
    setPostFailed(false);
    const getFacName = filterByValue(
      listOfFacilities,
      "facility_code",
      data.selectedFacility
    );
    const finalFacName = getFacName[0].facility_name;
    // console.log(data);
    const td = data.tableData.map((obj) => {
      return {
        ...obj,
        currentTotalBeds: parseInt(obj.currentTotalBeds),
        updatedTotalBeds: parseInt(obj.updatedTotalBeds)
          ? parseInt(obj.updatedTotalBeds)
          : parseInt(obj.currentTotalBeds),
      };
    });

    const payload = {
      bedTypesData: td,
      facilityName: finalFacName,
      firstName: data.firstName,
      lastName: data.lastName,
      emailAddress: data.email,
      dateTimeOfSubmission: Math.floor(Date.now() / 1000),
    };

    const url =
      process.env.REACT_APP_BASE_API + `/data-panel/${data.selectedFacility}`;
    // const url = "IA_Bed_Occupancy.json"

    try {
      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `${bearerToken}`,
          "Content-Type": "application/json",
        },
      });
      // console.log(response);
      setTableData(response.data.records);
      setPostSucceeded(true);
      setOpenInfo(true);
    } catch (error) {
      setPostFailed(true);
      console.error(error);
    }
  };

  const getDataForBeds = async (selectedFacility) => {
    setLoading(true);
    setFacilityName(selectedFacility.target.value);
    try {
      const url =
        process.env.REACT_APP_BASE_API +
        `/data-panel/${selectedFacility.target.value}`;
      // const url = "IA_Bed_Occupancy.json"
      const result = await axios
        .get(url, {
          headers: {
            Authorization: bearerToken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setLoading(false);
          setTableData(res.data);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const filterByValue = (arr, key, values) => {
    return arr.filter((item) => (values ? values.includes(item[key]) : ""));
  };
  const closeNotification = () => {
    setPostSucceeded(false);
    setPostFailed(false);
  };
  function unixTimeToLocal(timestamp) {
    // Create a new Date object with the Unix timestamp multiplied by 1000
    const date = new Date(timestamp * 1000);

    // Use the toLocaleString method to convert the date to a local time string
    const localTime = date.toLocaleString();

    // Return the local time string
    return localTime;
  }

  const handleGroupAuth = (str) => {
    return userGroups && userGroups.indexOf(str) !== -1;
  };
  if (handleGroupAuth("IDPH_IA_Beds_Data_Panel_Admins")) {
    return (
      <>
        <div className="mt-3 pt-5">
          <Snackbar
            open={postSucceeded}
            autoHideDuration={8000}
            onClose={closeNotification}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={closeNotification}
              severity="success"
              variant="filled"
            >
              Success : Data has been sent successfully!
            </Alert>
          </Snackbar>
          <Snackbar
            open={postFailed}
            autoHideDuration={7000}
            onClose={closeNotification}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={closeNotification}
              severity="error"
              variant="filled"
            >
              Error : We are unable to submit the data!
            </Alert>
          </Snackbar>
          <List>
            <ListItem className={classes.root}>
              <ListItemIcon>
                <ForwardToInboxRounded
                  className={classes.icon}
                  style={{ fontSize: "30px" }}
                />
              </ListItemIcon>
              <ListItemText>
                <div className="d-inline">
                  <ListItemText disableTypography>
                    <span className={classes.text}>Beds Data Panel</span>
                  </ListItemText>
                </div>
              </ListItemText>
            </ListItem>
          </List>
          <div className="alert alert-light">
            Authorized users agree information contained within the CyncHealth's
            Dashboards is confidential and agree to not disseminate such
            information contained therein.
          </div>
        </div>
        <Collapse in={openInfo}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenInfo(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            style={{ marginBottom: "5px", backgroundColor: "white" }}
            severity="info"
            variant="outlined"
          >
            <AlertTitle>Important Information</AlertTitle>
            The changes you have made to the Panel will reflect on the Bed
            Capacity Dashboard in
            <strong> 15 minutes!</strong>
          </Alert>
        </Collapse>
        {userInfo && (
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              marginTop: "10px",
              width: "100%",
              gap: "10px",

              padding: "20px",
              display: "flex",
              flex: "auto",
              flexWrap: "wrap",
              backgroundColor: "white",
              boxShadow: "2px black",
            }}
          >
            <TextField
              hidden
              {...register("firstName")}
              label="First Name"
              style={{ width: "calc(30% - 7.5px)" }}
              defaultValue={userInfo ? userInfo.given_name : ""}
              required
            />
            <TextField
              hidden
              {...register("lastName")}
              style={{ width: "calc(30% - 7.5px)" }}
              label="Last Name"
              defaultValue={userInfo ? userInfo.family_name : ""}
              required
            />
            <TextField
              hidden
              style={{ width: "calc(40% - 7.5px)" }}
              {...register("email")}
              label="Email"
              type="email"
              defaultValue={userInfo ? userInfo.email : ""}
              required
            />

            <FormControl fullWidth>
              <TextField
                {...register("selectedFacility")}
                onChange={getDataForBeds}
                value={facilityName || ""}
                select
                required
                defaultValue={facilityNames ? facilityNames[0] : ""}
                label="Please select the hospital"
              >
                {userInfo &&
                  filterByValue(
                    listOfFacilities,
                    "facility_code",
                    facilityNames
                  ).map((facility) => {
                    return (
                      <MenuItem value={facility.facility_code}>
                        {facility.facility_name}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </FormControl>
            {facilityName == null ? (
              <Box
                style={{
                  width: "100%",
                  backgroundColor: "#F4F7FA",
                  height: "200px",
                  textAlign: "center",
                  border: "1px",
                  borderRadius: "5px",
                  marginBottom: "5px",
                }}
              >
                <Typography paddingTop={10}>
                  Select the hospital to load the data.
                </Typography>
                <Refresh />
              </Box>
            ) : facilityName !== null && tableData.length < 1 ? (
              <Box
                style={{
                  width: "100%",
                  backgroundColor: "#F4F7FA",
                  height: "200px",
                  textAlign: "center",
                  border: "1px",
                  borderRadius: "5px",
                  marginBottom: "5px",
                }}
              >
                <Typography paddingTop={10}>
                  No Data! Please select other facility.
                </Typography>
                <Refresh />
              </Box>
            ) : (
              ""
            )}

            {tableData.length > 1 ? (
              <>
                <Table style={{ height: "100px" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Bed Types</TableCell>
                      <TableCell>Current Total Staffed Beds</TableCell>
                      <TableCell>Updated Total Staffed Beds</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {tableData.map((row, index) => (
                      <TableRow key={row.id} style={{ height: "10px" }}>
                        <TableCell>
                          <TextField
                            size="small"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& > fieldset": {
                                  border: "none",
                                },
                              },
                            }}
                            {...register(`tableData.${index}.bedType`)}
                            value={row.bedType}
                            InputProps={{
                              disableUnderline: true,

                              readOnly: true,
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            size="small"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& > fieldset": {
                                  border: "none",
                                },
                              },
                            }}
                            {...register(`tableData.${index}.currentTotalBeds`)}
                            value={
                              parseInt(row.currentTotalBeds)
                                ? parseInt(row.currentTotalBeds)
                                : "-"
                            }
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            onInput={(e) => {
                              const inputValue = parseInt(e.target.value);
                              if (inputValue > parseInt(row.totalBeds)) {
                                alert(
                                  `Error : 'Updated Total Staffed Beds' count cannot be greater than the total beds alloted for the facility ( ${row.facilityName}: ${row.totalBeds})! Please update the roster if you think the total beds count is greater than ${row.totalBeds}.`
                                );
                                e.target.value = parseInt(row.totalBeds);
                              } else {
                                e.target.value = Math.max(0, inputValue || 0);
                              }
                            }}
                            size="small"
                            {...register(`tableData.${index}.updatedTotalBeds`)}
                            type="number"
                            defaultValue={parseInt(row.currentTotalBeds)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}{" "}
                  </TableBody>
                </Table>
              </>
            ) : (
              ""
            )}
            {loading ? <LinearProgress /> : ""}

            <TextField
              variant="standard"
              style={{ width: "40%", marginTop: "10px" }}
              {...register("timeAdded")}
              label="Time of Submission"
              value={unixTimeToLocal(Math.floor(Date.now() / 1000))}
              InputProps={{
                readOnly: true,
              }}
            />

            {tableData.length > 1 ? (
              <Button type="submit" variant="contained">
                Submit
              </Button>
            ) : (
              <Button type="submit" variant="contained" disabled>
                Submit
              </Button>
            )}
          </Box>
        )}
      </>
    );
  } else {
    <NotFound />;
  }
}

export default BedDataPanel;
