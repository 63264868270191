import React, { useMemo, useState, useEffect } from "react";
import HotelRounded from "@mui/icons-material/HotelRounded";
import Paper from "@mui/material/Paper";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@mui/styles";
import { useOktaAuth } from "@okta/okta-react";
import LinearProgress from "@mui/material/LinearProgress";
import NotFound from "../../Alerts/NotFound";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import { Box, List } from "@mui/material";
import BedCapacityGroup from "./BedCapacityGroup";
import { LoadingButton } from "@mui/lab";
import { CalendarMonth, RefreshOutlined } from "@mui/icons-material";
import {
  LocalizationProvider,
  StaticDateRangePicker,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import dayjs from "dayjs";

// import dummyData from './dummyData.json'
const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "#2F5E8C",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between", // To move elements to the left and right edges
    padding: 1,
  },
  text: {
    marginLeft: "-10px",
    fontWeight: "bold",
    fontSize: "30px",
  },
  icon: {
    color: "#2F5E8C",
    marginRight: "7px",
  },
});

const shortcutsItems = [
  {
    label: "Last 30 Days",
    getValue: () => {
      const today = dayjs("04/07/24");
      return [today.subtract(29, "day"), today];
    },
  },
  {
    label: "Last 14 Days",
    getValue: () => {
      const today = dayjs("04/07/24");
      return [today.subtract(13, "day"), today];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = dayjs("04/07/24");
      return [today.subtract(6, "day"), today];
    },
  },
  { label: "Today", getValue: () => [null, null] },
];

export default function LiveBedCapacity() {
  console.log("version : sandbox");
  const [bedsData, setBedsData] = useState([]);
  const { authState, oktaAuth } = useOktaAuth();
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const classes = useStyles();
  const accessTokenResponse = authState.accessToken;
  const bearerToken = accessTokenResponse?.accessToken;
  const [selectedRange, setSelectedRange] = useState([null, null]); //  date range state for picker
  const [bedsDataDateRange, setBedsDataDateRange] = useState([null, null]); // date range state for data
  const [numberOfDaysSelected, setNumberOfDaysSelected] = useState(null);
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);

  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 480px)",
  });

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info.groups);
      });
    }
  }, [authState, oktaAuth]);

  const handleDateRangeChange = (newValue) => {
    const formattedDates = newValue.map((date) =>
      date ? dayjs(date).format("MM/DD/YYYY") : null
    );

    if (formattedDates[0] && formattedDates[1] != null) {
      setSelectedRange(newValue);
      setBedsDataDateRange(formattedDates);
      const startDate = dayjs(formattedDates[0]);
      const endDate = dayjs(formattedDates[1]);
      const numberOfDays = endDate.diff(startDate, "days") + 1; // Add 1 to include both start and end dates

      setNumberOfDaysSelected(numberOfDays);
    } else {
      setNumberOfDaysSelected(1);
      setBedsDataDateRange(formattedDates);
    }
  };
  const processData = (data, bedsDataDateRange) => {
    // List of keys to ignore when processing data

    const keysToIgnore = [
      "CNTY_FIPS",
      "COUNTY_NAME",
      "Definitive_ID",
      "Facility_AHA_ID",
      "HCC",
      "HOSPITAL_TYPE",
      "HQ_ADDRESS1",
      "HQ_CITY",
      "HQ_STATE",
      "HQ_ZIP_CODE",
      "LTPAC",
      "RMCC",
      "Resource_ID",
      "STATE_FIPS",
      "STATE_NAME",
      "Live_Refresh",
      "NDMS",
      "Status",
      "TRAUMA_LEVEL",
      "PHONE_MAIN",
      "PHONE_TRANSFER_CONTACT",
      "autoid",
      "lastupdate",
      // Add other keys to ignore if needed
    ];

    // Extracting start and end dates from bedsDataDateRange, if provided
    let startDate = null;
    let endDate = null;

    // Check if startDate and endDate are provided in bedsDataDateRange
    if (bedsDataDateRange[0]) {
      startDate = new Date(bedsDataDateRange[0]);
    }
    if (bedsDataDateRange[1]) {
      endDate = new Date(bedsDataDateRange[1]);
    }

    const maxDate = Math.max(...data.map((entry) => new Date(entry.Date)));
    console.log(dayjs(maxDate).format("MM/DD/YYYY"));
    const filteredData = data.filter(
      (item) =>
        dayjs(item.Date).format("MM/DD/YYYY") ===
        dayjs(maxDate).format("MM/DD/YYYY")
    );
    console.log(filteredData);
    // Grouping data based on hospital name and filtering based on date range
    const groupedData = data.reduce((groups, item) => {
      const currentDate = new Date(item.Date);
      const hospitalName = item.HOSPITAL_NAME;

      // Check if the item falls within the specified date range
      if (
        (!startDate || currentDate >= startDate) &&
        (!endDate || currentDate <= endDate)
      ) {
        if (!groups[hospitalName]) {
          // Initialize group object if not present
          groups[hospitalName] = {
            data: [], // Data related to this hospital
            numObjects: 0, // Number of objects in this group
            sum: {}, // Sum of numerical fields in this group
          };

          // // Initialize sum with keys to be summed up
          // keysToIgnore.forEach((key) => {
          //   groups[hospitalName].sum[key] = null; // Initialize sum for each key to null
          // });
        }

        // Check if any data fields are non-null
        const hasNonNullData = Object.values(item).some(
          (value) => value !== null
        );

        if (hasNonNullData) {
          // Store data for averaging
          groups[hospitalName].data.push(item);
          groups[hospitalName].numObjects++;

          // Sum numerical fields
          Object.keys(item).forEach((key) => {
            if (!keysToIgnore.includes(key) && typeof item[key] === "number") {
              // If key is not included for ignoring and value is a number
              groups[hospitalName].sum[key] =
                (groups[hospitalName].sum[key] || 0) + item[key];
            } else if (!keysToIgnore.includes(key) && item[key] === null) {
              // If key is not included for ignoring and value is null, set the key's value to null
              groups[hospitalName].sum[key] = null;
            }
          });
        }
      }

      return groups;
    }, {});

    console.log(groupedData, "gr");
    // Calculating average data for each group
    const averageData = Object.entries(groupedData).map(
      ([hospitalName, group]) => {
        const averageObject = {};

        // Include all keys from group.sum in the averageObject
        Object.keys(group.sum).forEach((key) => {
          averageObject[key] = null;
        });

        // Calculate average for each numerical field in the group
        Object.keys(group.sum).forEach((key) => {
          const value = group.sum[key];
          if (value !== null) {
            averageObject[key] = key.endsWith("_Change")
              ? (value / group.numObjects).toFixed(2) // If the field ends with "_Change", format to 2 decimal places
              : Math.round(value / group.numObjects); // Otherwise, round to the nearest integer
          }
        });

        // Include keysToIgnore in the final object
        keysToIgnore.forEach((key) => {
          if (!averageObject.hasOwnProperty(key)) {
            // If the key is not present in averageObject, find it in the original data and include it
            averageObject[key] = data.find(
              (item) => item.HOSPITAL_NAME === hospitalName
            )[key];
          }
        });
        return {
          HOSPITAL_NAME: hospitalName, // Add hospital name to the average object
          ...averageObject, // Add average data for numerical fields
          chartData: group.data.map((item) => ({
            Date: item.Date,
            Overview_Percentage_Occupied_Chart:
              item.Overview_Percentage_Occupied,
            Overview_Staffed_Percentage_Occupied_Chart:
              item.Overview_Staffed_Percentage_Occupied,
            ICU_Percentage_Occupied_Chart: item.Total_ICU_Percentage_Occupied,
            ICU_Staffed_Percentage_Occupied_Chart:
              item.Total_ICU_Staffed_Percentage_Occupied,
            ER_Percentage_Occupied_Chart: item.Total_ER_Percentage_Occupied,
            ER_Staffed_Percentage_Occupied_Chart:
              item.Total_ER_Staffed_Percentage_Occupied,
            Adult_ICU_Percentage_Occupied_Chart:
              item.Total_Adult_ICU_Percentage_Occupied,
            Adult_MedSurg_Percentage_Occupied_Chart:
              item.Total_Adult_MedSurg_Percentage_Occupied,
            Peds_ICU_Percentage_Occupied_Chart:
              item.Total_Peds_ICU_Percentage_Occupied,
            Pediatric_Percentage_Occupied_Chart:
              item.Total_Pediatric_Percentage_Occupied,
            Neonatal_Percentage_Occupied_Chart:
              item.Total_Neonatal_Percentage_Occupied,
            Psych_Child_Percentage_Occupied_Chart:
              item.Total_Psych_Child_Percentage_Occupied,
            Psych_Adult_Percentage_Occupied_Chart:
              item.Total_Psych_Adult_Percentage_Occupied,
            Psych_Geriatric_Percentage_Occupied_Chart:
              item.Total_Psych_Geriatric_Percentage_Occupied,
            Psych_Percentage_Occupied_Chart: item.Psych_Percentage_Occupied,
            Labor_Delivery_Percentage_Occupied_Chart:
              item.Total_Labor_Delivery_Percentage_Occupied,
            Newborn_Percentage_Occupied_Chart:
              item.Total_Newborn_Percentage_Occupied,
            Adult_ICU_Staffed_Percentage_Occupied_Chart:
              item.Total_Adult_ICU_Staffed_Percentage_Occupied,
            Adult_MedSurg_Staffed_Percentage_Occupied_Chart:
              item.Total_Adult_MedSurg_Staffed_Percentage_Occupied,
            Peds_ICU_Staffed_Percentage_Occupied_Chart:
              item.Total_Peds_ICU_Staffed_Percentage_Occupied,
            Pediatric_Staffed_Percentage_Occupied_Chart:
              item.Total_Pediatric_Staffed_Percentage_Occupied,
            Neonatal_Staffed_Percentage_Occupied_Chart:
              item.Total_Neonatal_Staffed_Percentage_Occupied,
            Psych_Child_Staffed_Percentage_Occupied_Chart:
              item.Total_Psych_Child_Staffed_Percentage_Occupied,
            Psych_Adult_Staffed_Percentage_Occupied_Chart:
              item.Total_Psych_Adult_Staffed_Percentage_Occupied,
            Psych_Geriatric_Staffed_Percentage_Occupied_Chart:
              item.Total_Psych_Geriatric_Staffed_Percentage_Occupied,
            Psych_Staffed_Percentage_Occupied_Chart:
              item.Psych_Staffed_Percentage_Occupied,
            Labor_Delivery_Staffed_Percentage_Occupied_Chart:
              item.Total_Labor_Delivery_Staffed_Percentage_Occupied,
            Newborn_Staffed_Percentage_Occupied_Chart:
              item.Total_Newborn_Staffed_Percentage_Occupied,
            // Add other properties as needed for chart data
          })),
        };
      }
    );
    const sliceLast14 = (data) => {
      // Sort the data based on Date in descending order
      const sortedData = data.sort((a, b) => new Date(b.Date) - new Date(a.Date));
  
      // Get the last 14 objects from sortedData
      const last14Objects = sortedData.slice(0, 14);
  
      return last14Objects;
  };
    
    const filteredDataWithChartData = filteredData.map((item) => {
      const averageObject = averageData.find(
        (obj) => obj.HOSPITAL_NAME === item.HOSPITAL_NAME
      );
      if (averageObject) {
        console.log(sliceLast14(averageObject.chartData))
        return {
          ...item,
          chartData: sliceLast14(averageObject.chartData),
        };
      } else {
        // If no corresponding average data is found, return the original item
        return item;
      }
    });
    console.log(filteredDataWithChartData)
    return startDate && endDate !== null
      ? averageData
      : filteredDataWithChartData; // Returning the processed average data
  };

  const getData = async () => {
    setLoading(true);
    try {
      const url = process.env.PUBLIC_URL + "/dummyData.json";
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();

      const averageData = processData(data, bedsDataDateRange);
      setBedsData(averageData);
      setLoading(false);
      setButtonLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const updateTime = () => {
    var current = new Date();
    return current.toLocaleString();
  };

  const handleScroll = () => {
    const scrollY = window.scrollY || window.pageYOffset;
    // Set a threshold value for scroll position
    const threshold = 300; // Adjust as needed
    if (scrollY > threshold) {
      setShowDateRangePicker(true);
    } else {
      setShowDateRangePicker(false);
    }
  };

  useEffect(() => {
    getData();
    window.addEventListener("scroll", handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [bedsDataDateRange]);

  const refreshData = (e) => {
    setButtonLoading(true);
    getData();
  };

  const handleGroupAuth = (str) => {
    return userInfo && userInfo.indexOf(str) !== -1;
  };

  if (handleGroupAuth("IDPH_IA_Bed_Occupancy_Users")) {
    return (
      <div>
        {isMobileDevice ? (
          <div className="mt-3 pt-5">
            <List>
              <ListItem>
                <ListItemIcon>
                  <HotelRounded fontSize="medium" className={classes.icon} />
                </ListItemIcon>

                <ListItemText>
                  <span>Bed Capacity Dashboard</span>
                  <span className="livebadge pulsate ml-2">NRT</span>{" "}
                  <div style={{ fontSize: "small", float: "right" }}>
                    {" "}
                    Last Updated : {updateTime()}
                    <LoadingButton
                      className="mt-2"
                      size="small"
                      onClick={(e) => refreshData(e)}
                      loading={buttonLoading}
                      loadingPosition="end"
                      variant="outlined"
                      endIcon={<RefreshOutlined />}
                    >
                      {" "}
                      Refresh
                    </LoadingButton>
                  </div>
                </ListItemText>
              </ListItem>
            </List>

            <div className="alert alert-light mt-2" style={{ width: "99%" }}>
              Authorized users agree information contained within the
              CyncHealth's Dashboards is confidential and agree to not
              disseminate such information contained therein.
            </div>
          </div>
        ) : (
          <div className="mt-5 pt-4">
            <Paper
              elevation={0}
              sx={{ padding: 2, display: "flex", alignItems: "center" }}
            >
              <List>
                <ListItem className={classes.root}>
                  <ListItemIcon>
                    <HotelRounded fontSize="large" className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText className={classes.text} disableTypography>
                    Bed Capacity Dashboard
                  </ListItemText>
                </ListItem>
              </List>
              <span className="livebadge pulsate">NRT</span>
              {showDateRangePicker && (
                <div
                  style={{
                    position: "fixed",
                    top: "80px", // Adjust as needed
                    right: 40, // Adjust as needed

                    zIndex: 9999, // Ensure it appears above other content
                  }}
                >
                  <Box sx={{ marginX: "10px" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateRangePicker
                        sx={{
                          ".css-1b6m7bd": {
                            width: "157px",
                          },
                        }}
                        slots={{ field: SingleInputDateRangeField }}
                        slotProps={{
                          textField: {
                            size: "small",
                            InputProps: { endAdornment: <CalendarMonth /> },
                          },
                          shortcuts: {
                            items: shortcutsItems,
                          },
                          actionBar: { actions: [] },
                        }}
                        name="allowedRange"
                        calendars={2}
                        minDate={dayjs("03/07/2024")}
                        maxDate={dayjs("04/07/2024")}
                        value={selectedRange}
                        onChange={handleDateRangeChange}
                      />
                    </LocalizationProvider>
                  </Box>
                </div>
              )}
              <Box sx={{ marginX: "10px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateRangePicker
                    sx={{
                      ".css-1b6m7bd": {
                        width: "280px",
                      },
                    }}
                    slots={{ field: SingleInputDateRangeField }}
                    slotProps={{
                      textField: {
                        size: "small",
                        InputProps: { endAdornment: <CalendarMonth /> },
                      },
                      shortcuts: {
                        items: shortcutsItems,
                      },
                      actionBar: { actions: [] },
                    }}
                    name="allowedRange"
                    calendars={2}
                    minDate={dayjs("03/07/2024")}
                    maxDate={dayjs("04/07/2024")}
                    value={selectedRange}
                    onChange={handleDateRangeChange}
                  />
                </LocalizationProvider>
              </Box>
              <Box sx={{ marginLeft: "10px" }}>
                <span style={{ color: "gray" }}>
                  Last Updated: {updateTime()}
                </span>
                <LoadingButton
                  onClick={refreshData}
                  loading={buttonLoading}
                  loadingPosition="end"
                  startIcon={<RefreshOutlined />}
                >
                  {/* Your button text */}
                </LoadingButton>
              </Box>
            </Paper>
            <div className="alert alert-light mt-2">
              Authorized users agree information contained within the
              CyncHealth's Dashboards is confidential and agree to not
              disseminate such information contained therein.
            </div>
          </div>
        )}

        {/* <span style={{ marginRight: '8px' }}>Select Date Range :</span> Label */}

        <Paper elevation={2} style={{ padding: "8px", height: "auto" }}>
          {loading ? (
            <LinearProgress />
          ) : (
            <BedCapacityGroup beds={bedsData} days={numberOfDaysSelected} />
          )}
        </Paper>

        <div
          className="alert alert-light mt-4 mr-1"
          role="alert"
          style={{ fontSize: "12px" }}
        >
          <div style={{ marginTop: "10px" }}>
            This Dashboard is provided as a service to Authorized Users pursuant
            to applicable Data Sharing and Participation Agreements and in
            compliance with all applicable laws. Users are restricted from the
            following activities pursuant to the Agreements: make this Service
            available to any other person, entity, or business; copy,
            reverse-engineer, decompile, or disassemble the System or data
            contained herein; and modify or combine the System with any other
            software or services not provided or approved by CyncHealth.
          </div>

          <div style={{ marginTop: "10px" }}>
            In addition, this information may be confidential or proprietary and
            should be withheld from public record requests under Iowa Code §
            22.7 (specifically §§ 22.7(3) or (6))
          </div>

          <div style={{ marginTop: "10px" }}>
            <b>Licensed Beds</b>
            <br />
            The maximum number of beds for which a hospital holds a license to
            operate.
          </div>

          <div style={{ marginTop: "10px" }}>
            <b>Physically Available Beds</b>
            <br />
            Physically available are licensed, physically set up, and available
            for use. These are beds regularly maintained in the hospital for the
            use of patients, which furnish accommodations with supporting
            services (such as food, laundry, and housekeeping). The number of
            physically available beds for a facility are configured by the
            facility’s designated Bed Manager on the Beds Roster Administrative
            Portal (which includes Room Number, Bed Type, Bed Number, Bed
            Status). If the facility’s designated Bed Manager has not configured
            the number of physically available beds within the application,
            reporting defaults to the number of licensed beds.
          </div>

          <div style={{ marginTop: "10px" }}>
            <b>Staffed Beds</b>
            <br />
            Beds that are licensed and physically available for which staff is
            on hand to attend to the patient who occupies the bed. Includes both
            occupied and Vacant/Available beds with staff on hand to attend to
            the patient.
          </div>

          <div style={{ marginTop: "10px" }}>
            <b>Unstaffed beds</b>
            <br />
            Beds that are licensed and physically available and have no current
            staff on hand to attend to a patient who would occupy the bed.
          </div>

          <div style={{ marginTop: "10px" }}>
            <b>Occupied Beds</b>
            <br />
            Beds that are licensed, physically available, staffed, and occupied
            by a patient. This is often termed “census”. This near-real-time
            (NRT) bed occupancy data generates from the state Health Information
            Exchange (CyncHealth) and only contains those facilities that are
            actively sharing data with CyncHealth. Data is near-real time
            (within 15 minutes) with possible delays that may occur due to the
            time taken to ingest data from the facility until the time it can be
            displayed by CyncHealth. Beds occupied are obtained from Admission,
            Discharge, Transfer (ADT) events and can only reflect the data
            received from each facility by CyncHealth.
          </div>

          <div style={{ marginTop: "10px" }}>
            <b>Vacant/Available Beds</b>
            <br />
            Beds that are vacant and to which patients can be transported
            immediately. These must include supporting space, equipment, medical
            material, ancillary and support services, and staff to operate under
            normal circumstances. These beds are licensed, physically available,
            and have staff on hand to attend to the patient who occupies the
            bed.
          </div>

          <div style={{ marginTop: "10px" }}>
            <b>Unoccupied Beds</b>
            <br />
            Beds that are licensed, physically available but not occupied by a
            patient.
          </div>

          <div style={{ marginTop: "10px" }}>
            <b>Physical Capacity</b>
            <br />
            Ratio of Occupied Beds to Physically Available Beds
          </div>

          <div style={{ marginTop: "10px" }}>
            <b>Staffed Capacity</b>
            <br />
            Ratio of Occupied beds to Staffed Beds <br /> (Note: This
            calculation will not be accurate until after 4/1/24, when all
            facilities are expected to have entered/reported their baseline
            “staffed” beds.)
          </div>

          <div style={{ marginTop: "10px" }}>
            <b>https://loinc.org/92194-0</b>
          </div>
        </div>
      </div>
    );
  } else {
    return <NotFound />;
  }
}
