// src/CardChart.js
import React from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Avatar,
} from "@mui/material";
import { AreaChart, Area, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import PersonIcon from "@mui/icons-material/Person";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import dayjs from "dayjs";
import { RocketLaunch, Update } from "@mui/icons-material";

const DashboardCard = ({ icon, title, value, trend, chart }) => {
  return (
    <Card sx={{ borderRadius: "10px" }}>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            {" "}
            <Avatar sx={{ bgcolor: trend }}>{icon}</Avatar>
          </Grid>
          <Grid item xs>
            <Typography variant="body2">{title}</Typography>
            <Typography fontWeight="bold" variant="h6">
              {value}
            </Typography>
          </Grid>
        </Grid>
        <Box mt={1}>
          <ResponsiveContainer width="100%" height={80}>
            <AreaChart data={chart}>
              <XAxis
                dataKey={
                  title === "Update Frequency Per Day"
                    ? "lastupdatedon"
                    : title === "Peak Update Time"
                    ? "max_last_update_timestamp"
                    : "weekday_of_lastupdate"
                }
                label={
                  dayjs(value)
                    .tz("America/Chicago")
                    .startOf("hour")
                    .format("hh:mm A") +
                  " - " +
                  dayjs(value)
                    .tz("America/Chicago")
                    .startOf("hour")
                    .add(1, "hour")
                    .format("hh:mm A") +
                  " CST"
                }
                hide
              />

              <Area
                type="monotone"
                dataKey={
                  title === "Update Frequency Per Day"
                    ? "number_of_updates_by_day"
                    : title === "Peak Update Time"
                    ? "number_of_updates_by_hour"
                    : "number_of_updates_by_day_wk"
                }
                stroke={trend}
                strokeWidth={2}
                fill={trend}
              />
              <Tooltip
                viewBox={{ x: 0, y: 0, width: 200, height: 400 }}
                labelFormatter={(value) =>
                  title === "Peak Update Time"
                    ? dayjs(value)
                        .tz("America/Chicago")
                        .startOf("hour")
                        .format("hh:mm A") +
                      " - " +
                      dayjs(value)
                        .tz("America/Chicago")
                        .startOf("hour")
                        .add(1, "hour")
                        .format("hh:mm A") +
                      " CST"
                    : value
                }
                formatter={(value) => [
                  title === "Update Frequency Per Day"
                    ? `Number of Updates Per Day: ${value}`
                    : title === "Peak Update Time"
                    ? `Number of Updates By Hour: ${value}`
                    : `Number of Updates by Week Day: ${value}`,
                ]}
              />
            </AreaChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
};

const CardChart = ({
  chartData,
  updateFrequency,
  peakUpdateTime,
  peakUpdateDay,
}) => (
  <Box pt={1}>
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4} md={4}>
        <DashboardCard
          icon={<Update style={{ fontSize: 20 }} />}
          title="Update Frequency Per Day"
          value={updateFrequency}
          percentage={40}
          trend="#83BE40"
          chart={chartData}
        />
      </Grid>
      <Grid item xs={12} sm={8} md={4}>
        <DashboardCard
          icon={<RocketLaunch style={{ fontSize: 20 }} />}
          title="Peak Update Time"
          value={peakUpdateTime}
          percentage={25}
          trend="#F3AA1B"
          chart={chartData}
        />
      </Grid>
      <Grid item xs={12} sm={8} md={4}>
        <DashboardCard
          icon={<TrendingUpIcon style={{ fontSize: 20 }} />}
          title="Peak Update Day"
          value={peakUpdateDay}
          percentage={-12}
          trend="#09AED9"
          chart={chartData}
        />
      </Grid>
    </Grid>
  </Box>
);

export default CardChart;
