import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import "./LiveBedCapacity.css";
import "./Table.css";
import TotalAvailableBeds from "./TotalAvailableBeds";
import { styled } from "@mui/material/styles";
import CustomTable from "../../services/CustomTable";
import PercentageChangeStyles from "../../services/PercentageChangeStyles";
import PercentageStyles from "../../services/PercentageStyles";
import TrendHeaderText from "../../services/TrendHeaderText";
import CustomBarChart from "../../services/CustomBarChart";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import HospitalInfoTooltip from "../../services/HospitalDetails";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "#2F5E8C",
  },
  text: {
    marginLeft: "-20px",
    fontStyle: "bold",
  },
  icon: {
    color: "#2F5E8C",
    marginLeft: "-10px",
  },
  tabs: {},
});

function a11yProps(index) {
  return {
    id: `full-width-tabpanel-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    maxWidth: 300,
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
    lineHeight: 2,
  },
}));

const calculateTotal = (rows, accessor) => {
  return rows.reduce((sum, row) => {
    const value = row.values[accessor];
    // Check if the accessor exists in the row and its value is not null or "-"
    if (!value || value === null || value === "-") {
      return sum; // Ignore this value
    }
    return sum + value; // Add the value to the sum
  }, 0);
};

export default function PsychiatricBedsGroup({ data, days }) {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const commanColumns = [
    {
      // first group - TV Show
      Header: "Hospital Name",
      accessor: "HOSPITAL_NAME",
      disableFilters: true,
      Footer: "Total",
      Cell: (row) => {
        return (
          <div>
            <HospitalInfoTooltip row={row} />
          </div>
        );
      },
    },
    {
      Header: "Trauma Levels",
      accessor: "TRAUMA_LEVEL",
      disableFilters: true,
      Footer: "",
    },
    {
      Header: "Hospital Type",
      accessor: "HOSPITAL_TYPE",
      disableFilters: true,
      Footer: "",
    },
    {
      Header: "NMDS",
      accessor: "NMDS",
      disableFilters: true,
      Footer: "",
    },
    {
      Header: "Hospital City",
      accessor: "HQ_CITY",
      disableFilters: true,
      Footer: "",
    },
    {
      Header: "Hospital County",
      accessor: "COUNTY_NAME",
      disableFilters: true,
      show: false,
      Footer: "",
    },
    {
      Header: "Hospital Zip",
      accessor: "HQ_ZIP_CODE",
      disableFilters: true,
      Footer: "",
    },
    {
      Header: "AHA Number",
      accessor: "Facility_AHA_ID",
      disableFilters: true,
      Footer: "",
    },
    {
      Header: "RMCC",
      accessor: "RMCC",
      disableFilters: true,
      show: false,
      Footer: "",
    },
    {
      Header: "HCC",
      accessor: "HCC",
      disableFilters: true,
      show: false,
      Footer: "",
    },
  ];
  const columnsAllPsychiatric = useMemo(
    (data) => [
      ...commanColumns,
      {
        // first group - TV Show
        Header: `All Psychiatric Beds ${
          days === 1 || days == null ? "" : "(Last " + days + " days of data)"
        }`,
        tipText: `Inpatient beds used for child, adult or geriatric psychiatric care. Beds displaying here have been
        designated on the bed roster as ‘Psych Geriatric Beds’ or ‘Psych Child Beds’ or ‘Psych Adult Beds’. Does
        not include Adult or Pediatric Medical/Surgical, Adult ICU or Pediatric (PICU) beds.`,
        Footer: "",
        // First group columns
        columns: [
          {
            Header: "Physically Available",
            accessor: "Psych_Licensed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Staffed",
            accessor: "Psych_Staffed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Unstaffed",
            accessor: "Psych_Unstaffed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Occupied",
            accessor: "Psych_Occupied_Beds",
            id: "occupiedBeds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Vacant/ Available",
            accessor: "Psych_Available_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Physical Capacity",
            tipText: `Ratio of Occupied Beds to Physically Available Beds`,
            accessor: "Psych_Percentage_Occupied",
            disableFilters: true,
            Cell: ({ value }) => PercentageStyles(value),
            Footer: "",
          },
          {
            Header: "Physical Capacity % Change",
            accessor: "Psych_Percentage_Occupied_Change",
            disableFilters: true,
            Cell: ({ value }) => PercentageChangeStyles(value),
            Footer: "",
          },
          {
            Header: "Staffed Capacity",
            tipText: `Ratio of Occupied Beds to Staffed Bed`,
            accessor: "Psych_Staffed_Percentage_Occupied",
            disableFilters: true,
            Cell: ({ value }) => PercentageStyles(value),
            Footer: "",
          },
          {
            Header: "Staffed Capacity % Change",
            accessor: "Psych_Staffed_Percentage_Occupied_Change",
            disableFilters: true,
            Cell: ({ value }) => PercentageChangeStyles(value),
            Footer: "",
          },
          {
            id: "trend",
            disableSortBy: true,
            Header: () => <TrendHeaderText days={days} />,
            // Header: "14 Days Trend - Physical & Staffed Capacity",
            tipText: `${
              days === 1 || days == null ? "30 " : days + " "
            }Days Trend - Physical & Staffed Capacity `,
            accessor: "chartData",
            disableFilters: true,
            Cell: ({ cell }) => (
              <CustomBarChart
                cell={cell}
                physicalDataField="Psych_Percentage_Occupied_Chart"
                staffedDataField="Psych_Staffed_Percentage_Occupied_Chart"
              />
            ),
            Footer: "",
          },
        ],
      },
    ],
    []
  );
  const columnsPsychiatricChild = useMemo(
    (data) => [
      ...commanColumns,
      {
        Header: `Psychiatric Child ${
          days === 1 || days == null ? "" : "(Last " + days + " days of data)"
        }`,
        tipText: `Age 0-17, psychiatric beds are utilized for the treatment of mental health diagnosis and disorders.  Care includes monitoring and intervention of behavioral, mental and psychosocial diagnosis.`,
        Footer: "",
        columns: [
          {
            Header: "Physically Available",
            accessor: "Total_Psych_Child_Licensed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Staffed",
            accessor: "Total_Psych_Child_Staffed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Unstaffed",
            accessor: "Total_Psych_Child_Unstaffed_Beds",
            Footer: (info) => calculateTotal(info.rows, info.column.id),
            disableFilters: true,
          },
          {
            Header: "Occupied",
            accessor: "Total_Psych_Child_Occupied_Beds",
            id: "occupiedBeds",
            Footer: (info) => calculateTotal(info.rows, info.column.id),
            disableFilters: true,
          },
          {
            Header: "Vacant/ Available",
            accessor: "Total_Psych_Child_Available_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Physical Capacity",
            tipText: `Ratio of Occupied Beds to Physically Available Beds`,
            accessor: "Total_Psych_Child_Percentage_Occupied",
            disableFilters: true,
            Cell: ({ value }) => PercentageStyles(value),
            Footer: "",
          },
          {
            Header: "Physical Capacity % Change",
            accessor: "Total_Psych_Child_Percentage_Occupied_Change",
            disableFilters: true,
            Cell: ({ value }) => PercentageChangeStyles(value),
            Footer: "",
          },
          {
            Header: "Staffed Capacity",
            tipText: `Ratio of Occupied Beds to Staffed Bed`,
            accessor: "Total_Psych_Child_Staffed_Percentage_Occupied",
            disableFilters: true,
            Cell: ({ value }) => PercentageStyles(value),
            Footer: "",
          },
          {
            Header: "Staffed Capacity % Change",
            accessor: "Total_Psych_Child_Staffed_Percentage_Occupied_Change",
            disableFilters: true,
            Cell: ({ value }) => PercentageChangeStyles(value),
            Footer: "",
          },
          {
            id: "trend",
            disableSortBy: true,
            Header: () => <TrendHeaderText days={days} />,
            // Header: "14 Days Trend - Physical & Staffed Capacity",
            tipText: `${
              days === 1 || days == null ? "30 " : days + " "
            }Days Trend - Physical & Staffed Capacity `,
            accessor: "chartData",
            disableFilters: true,
            Cell: ({ cell }) => (
              <CustomBarChart
                cell={cell}
                physicalDataField="Psych_Child_Percentage_Occupied_Chart"
                staffedDataField="Psych_Child_Staffed_Percentage_Occupied_Chart"
              />
            ),
            Footer: "",
          },
        ],
      },
    ],
    []
  );
  const columnsPsychiatricAdult = useMemo(
    (data) => [
      ...commanColumns,
      {
        Header: `Psychiatric Adult ${
          days === 1 || days == null ? "" : "(Last " + days + " days of data)"
        }`,
        tipText: `Age 0-17, psychiatric beds are utilized for the treatment of mental health diagnosis and disorders.  Care includes monitoring and intervention of behavioral, mental and psychosocial diagnosis.`,
        Footer: "",
        columns: [
          {
            Header: "Physically Available",
            accessor: "Total_Psych_Adult_Licensed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Staffed",
            accessor: "Total_Psych_Adult_Staffed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Unstaffed",
            accessor: "Total_Psych_Adult_Unstaffed_Beds",
            Footer: (info) => calculateTotal(info.rows, info.column.id),
            disableFilters: true,
          },
          {
            Header: "Occupied",
            accessor: "Total_Psych_Adult_Occupied_Beds",
            id: "occupiedBeds",
            Footer: (info) => calculateTotal(info.rows, info.column.id),
            disableFilters: true,
          },
          {
            Header: "Vacant/ Available",
            accessor: "Total_Psych_Adult_Available_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Physical Capacity",
            tipText: `Ratio of Occupied Beds to Physically Available Beds`,
            accessor: "Total_Psych_Adult_Percentage_Occupied",
            disableFilters: true,
            Cell: ({ value }) => PercentageStyles(value),
            Footer: "",
          },
          {
            Header: "Physical Capacity % Change",
            accessor: "Total_Psych_Adult_Percentage_Occupied_Change",
            disableFilters: true,
            Cell: ({ value }) => PercentageChangeStyles(value),
            Footer: "",
          },
          {
            Header: "Staffed Capacity",
            tipText: `Ratio of Occupied Beds to Staffed Bed`,
            accessor: "Total_Psych_Adult_Staffed_Percentage_Occupied",
            disableFilters: true,
            Cell: ({ value }) => PercentageStyles(value),
            Footer: "",
          },
          {
            Header: "Staffed Capacity % Change",
            accessor: "Total_Psych_Adult_Staffed_Percentage_Occupied_Change",
            disableFilters: true,
            Cell: ({ value }) => PercentageChangeStyles(value),
            Footer: "",
          },
          {
            id: "trend",
            disableSortBy: true,
            Header: () => <TrendHeaderText days={days} />,
            // Header: "14 Days Trend - Physical & Staffed Capacity",
            tipText: `${
              days === 1 || days == null ? "30 " : days + " "
            }Days Trend - Physical & Staffed Capacity `,
            accessor: "chartData",
            disableFilters: true,
            Cell: ({ cell }) => (
              <CustomBarChart
                cell={cell}
                physicalDataField="Psych_Adult_Percentage_Occupied_Chart"
                staffedDataField="Psych_Adult_Staffed_Percentage_Occupied_Chart"
              />
            ),
            Footer: "",
          },
        ],
      },
    ],
    []
  );
  const columnsPsychiatricGeriatric = useMemo(
    (data) => [
      ...commanColumns,
      {
        Header: `Psychiatric Child ${
          days === 1 || days == null ? "" : "(Last " + days + " days of data)"
        }`,
        tipText: `Age 0-17, psychiatric beds are utilized for the treatment of mental health diagnosis and disorders.  Care includes monitoring and intervention of behavioral, mental and psychosocial diagnosis.`,
        Footer: "",
        columns: [
          {
            Header: "Physically Available",
            accessor: "Total_Psych_Geriatric_Licensed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Staffed",
            accessor: "Total_Psych_Geriatric_Staffed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Unstaffed",
            accessor: "Total_Psych_Geriatric_Unstaffed_Beds",
            Footer: (info) => calculateTotal(info.rows, info.column.id),
            disableFilters: true,
          },
          {
            Header: "Occupied",
            accessor: "Total_Psych_Geriatric_Occupied_Beds",
            id: "occupiedBeds",
            Footer: (info) => calculateTotal(info.rows, info.column.id),
            disableFilters: true,
          },
          {
            Header: "Vacant/ Available",
            accessor: "Total_Psych_Geriatric_Available_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id),
          },
          {
            Header: "Physical Capacity",
            tipText: `Ratio of Occupied Beds to Physically Available Beds`,
            accessor: "Total_Psych_Geriatric_Percentage_Occupied",
            disableFilters: true,
            Cell: ({ value }) => PercentageStyles(value),
            Footer: "",
          },
          {
            Header: "Physical Capacity % Change",
            accessor: "Total_Psych_Geriatric_Percentage_Occupied_Change",
            disableFilters: true,
            Cell: ({ value }) => PercentageChangeStyles(value),
            Footer: "",
          },
          {
            Header: "Staffed Capacity",
            tipText: `Ratio of Occupied Beds to Staffed Bed`,
            accessor: "Total_Psych_Geriatric_Staffed_Percentage_Occupied",
            disableFilters: true,
            Cell: ({ value }) => PercentageStyles(value),
            Footer: "",
          },
          {
            Header: "Staffed Capacity % Change",
            accessor:
              "Total_Psych_Geriatric_Staffed_Percentage_Occupied_Change",
            disableFilters: true,
            Cell: ({ value }) => PercentageChangeStyles(value),
            Footer: "",
          },
          {
            id: "trend",
            disableSortBy: true,
            Header: () => <TrendHeaderText days={days} />,
            // Header: "14 Days Trend - Physical & Staffed Capacity",
            tipText: `${
              days === 1 || days == null ? "30 " : days + " "
            }Days Trend - Physical & Staffed Capacity `,
            accessor: "chartData",
            disableFilters: true,
            Cell: ({ cell }) => (
              <CustomBarChart
                cell={cell}
                physicalDataField="Psych_Geriatric_Percentage_Occupied_Chart"
                staffedDataField="Psych_Geriatric_Staffed_Percentage_Occupied_Chart"
              />
            ),
            Footer: "",
          },
        ],
      },
    ],
    []
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box
        className="sticky-thc-tabs"
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tabs
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="inherit"
          variant="scrollable"
        >
          <Tab
            sx={{
              borderRight: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            label="All Psychiatric"
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              borderRight: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            label="Psychiatric Child"
            {...a11yProps(1)}
          />
          <Tab
            sx={{
              borderRight: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            label="Psychiatric Adult"
            {...a11yProps(2)}
          />
          <Tab
            sx={{
              borderRight: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            label="Psychiatric Older Adult"
            {...a11yProps(3)}
          />
        </Tabs>
      </Box>
      <Box sx={{ width: "100%" }}>
        <TabPanel value={value} index={0}>
          <TotalAvailableBeds beds={data} type="Psych_" />
          <CustomTable
            data={data}
            days={days}
            columns={columnsAllPsychiatric}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TotalAvailableBeds beds={data} type="Total_Psych_Child_" />
          <CustomTable
            data={data}
            days={days}
            columns={columnsPsychiatricChild}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TotalAvailableBeds beds={data} type="Total_Psych_Adult_" />
          <CustomTable
            data={data}
            days={days}
            columns={columnsPsychiatricAdult}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TotalAvailableBeds beds={data} type="Total_Psych_Geriatric_" />
          <CustomTable
            data={data}
            days={days}
            columns={columnsPsychiatricGeriatric}
          />
        </TabPanel>
      </Box>
    </div>
  );
}
